import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Checkbox, Progress, Spin, Steps, Upload } from 'antd';
import { ImportOutlined, UploadOutlined } from '@ant-design/icons';
import { isEmpty, capitalize } from 'lodash';

import Input from '../../Input';
import TextArea from '../../TextArea';
import Modal from '../GenericModal';
import Button from '../../Button';
import useBotModal from './hooks';
import ROUTES from 'constants/routes';
import {
  StyledFooterSubmit,
  StyledBotModalFooter,
  StyledActionButtons,
  StyledImportLabel,
  StyledAntUploadContent,
  StyledExportContainer,
  StyledExportLabel,
  StyledImportLabelError,
} from './BotModal.styles';
import Alert from 'components/Alert';
import { ZSB_CONTACT } from 'constants/outboundLinks';
import LimitationTooltip from 'components/ToolTips/LimitationToolTip';
import Select from 'components/Select';
import { StyledFlexLeftColumn } from 'styles/GenericStyledComponents';
import { withFeatureFlag } from 'components/hoc/withFeatureFlags';
import { BOT_MODE } from 'constants/featureFlags';
import Paragraph from 'antd/lib/typography/Paragraph';

const { Dragger } = Upload;
const { Step } = Steps;

const BotModalForm = ({
  handleModalSubmit,
  botModes,
  botMode,
  action,
  setBotDesc,
  setBotName,
  botName,
  setBotMode,
  botDesc,
  webSocketIsInprogress,
}) => {
  const BotModeSelect = withFeatureFlag(
    BOT_MODE,
    <Select
      label="Bot mode"
      options={botModes}
      onChange={setBotMode}
      value={botMode}
      required
      placeholder="Select bot mode..."
      disabled={action === 'edit' || webSocketIsInprogress}
    />
  );
  return (
    <StyledFlexLeftColumn data-testid="add-new-bot-modal">
      <form onSubmit={handleModalSubmit} style={{ width: '100%' }}>
        <Input
          required
          label="Bot Name"
          type="text"
          onChange={e => setBotName(e.target.value)}
          placeholder="Enter bot name"
          defaultValue={botName}
          maxLength="30"
          disabled={webSocketIsInprogress}
        />
        <BotModeSelect />
        <TextArea
          placeholder="Enter bot description"
          label="Bot Description"
          type="text"
          onChange={e => setBotDesc(e.target.value)}
          autoSize={{ minRows: 5, maxRows: 5 }}
          defaultValue={botDesc}
          disabled={webSocketIsInprogress}
        />
      </form>
    </StyledFlexLeftColumn>
  );
};

BotModalForm.propTypes = {
  handleModalSubmit: PropTypes.func,
  botModes: PropTypes.array,
  botMode: PropTypes.string,
  action: PropTypes.string,
  setBotDesc: PropTypes.func,
  setBotName: PropTypes.func,
  botName: PropTypes.string,
  setBotMode: PropTypes.func,
  botDesc: PropTypes.string,
  webSocketIsInprogress: PropTypes.bool,
};

const BotModal = ({
  bot,
  disabled,
  title,
  visibility,
  onCancel,
  handleCreateNewBot,
  onOk,
  ...rest
}) => {
  const {
    botAnswer,
    botWebsite,
    botInfo,
    botMode,
    botModes,
    botMetaData,
    botName,
    botDesc,
    botModalState,
    importStep,
    isImportReady,
    isImporting,
    isCreatingNewBot,
    isEditingBot,
    warnMsg,
    exportOptions,
    maxBotCount,
    planType,
    setBotMode,
    selectExportType,
    handleCloseBotModal,
    handleToggleImportModal,
    handleReadBot,
    handleModalSubmit,
    handleChangeImportType,
    handleChangeStep,
    setBotName,
    setBotDesc,
    isMaxBotCountReached,
    isBotModalVisible,
    importExportEnabled,
    loading,
    isImportSaveBtnDisabled,
    webSocket,
    webSocketIsInprogress,
  } = useBotModal();

  const maxBotCountReachedMessage = () => (
    <div data-testid="max-bot-count-reached">
      <p>You have reached the maximum allowed bots of {maxBotCount}.</p>
      {planType === 'advanced' ? (
        <p>
          <a href={ZSB_CONTACT} target="_blank" rel="noreferrer">
            Contact Us
          </a>{' '}
          for support.
        </p>
      ) : (
        <p>
          Please <Link to={ROUTES.PLAN_AND_PAYMENTS}>upgrade</Link> your plan to
          increase bot count.
        </p>
      )}
    </div>
  );

  const BOT_MODAL_STEPS = [
    {
      title: 'Import Bot',
      content: () => (
        <Dragger
          headers={{ 'Content-Type': 'multipart/form-data' }}
          beforeUpload={handleReadBot}
          accept="application/json"
          listType="picture"
          maxCount={1}
        >
          <StyledAntUploadContent>
            <ImportOutlined />
            {'Drag files here or select file'}
          </StyledAntUploadContent>
        </Dragger>
      ),
    },
    {
      title: 'Save',
      content: () => (
        <BotModalForm
          handleModalSubmit={handleModalSubmit}
          botInfo={botInfo}
          botModes={botModes}
          botMode={botMode}
          action={botModalState.action}
          setBotDesc={setBotDesc}
          setBotName={setBotName}
          botName={botName}
          setBotMode={setBotMode}
          botDesc={botDesc}
          webSocketIsInprogress={webSocketIsInprogress}
        />
      ),
    },
  ];

  const renderAddBotForm = isImporting => {
    return isImporting ? (
      <>
        <Steps current={importStep} onChange={handleChangeStep}>
          {BOT_MODAL_STEPS.map((step, idx) => (
            <Step
              title={importStep !== idx ? 'Change Import File' : step.title}
              key={idx}
              disabled={isEmpty(botInfo?.length) && idx > importStep}
            />
          ))}
        </Steps>
        <div className="steps-content">
          {webSocketIsInprogress && (
            <Progress
              percent={webSocket?.percentage}
              strokeColor="#108ee9"
              trailColor="#E8F0FD"
            />
          )}
          {BOT_MODAL_STEPS[importStep].content()}
        </div>
      </>
    ) : (
      <BotModalForm
        handleModalSubmit={handleModalSubmit}
        botModes={botModes}
        botMode={botMode}
        action={botModalState.action}
        setBotDesc={setBotDesc}
        setBotName={setBotName}
        botName={botName}
        setBotMode={setBotMode}
        botDesc={botDesc}
      />
    );
  };
  const ExportOptionSelect = () => (
    <StyledExportContainer>
      <StyledExportLabel>{'Select data to be imported'}:</StyledExportLabel>

      {exportOptions?.map((option, idx) => (
        <div key={idx}>
          {option.children ? (
            <>
              <Paragraph
                level={5}
                style={{
                  margin: '9px 0px 0px 0px',
                  fontWeight: 'normal',
                }}
              >
                {option.label}:
              </Paragraph>
              <Checkbox.Group
                options={option.children}
                onChange={e => handleChangeImportType(e, option.children)}
                style={{ marginLeft: 16 }}
                defaultValue={selectExportType}
                disabled={
                  (!selectExportType.includes('answer') &&
                    !selectExportType.includes('website') &&
                    !selectExportType.includes('file') &&
                    option.children.value !== 'answer') ||
                  webSocketIsInprogress
                }
              />
            </>
          ) : (
            <Checkbox
              onChange={handleChangeImportType}
              value={option.value}
              checked={selectExportType.includes(option.value)}
              disabled={
                (!selectExportType.includes('answer') &&
                  option.value !== 'answer') ||
                webSocketIsInprogress
              }
            >
              {option.label}
            </Checkbox>
          )}
        </div>
      ))}
    </StyledExportContainer>
  );

  const renderLeftFooterContent = isImporting => {
    const showLeftFooterButton =
      (!botMetaData || !botAnswer || !botWebsite) && importExportEnabled;

    if (showLeftFooterButton && isCreatingNewBot) {
      return (
        <Button
          disabled={disabled}
          startIcon={!isImporting ? <UploadOutlined /> : null}
          variant="link"
          value={!isImporting ? 'Import Bot' : 'Cancel Import'}
          onClick={handleToggleImportModal}
        />
      );
    } else if (!importExportEnabled && isCreatingNewBot) {
      return (
        <LimitationTooltip
          title="Upgrade to Import Bot"
          disabledFeat={
            <>
              <UploadOutlined /> <>{'Import Bot'}</>
            </>
          }
          placement="top"
        />
      );
    } else if (
      (botMetaData || botAnswer || botWebsite) &&
      isCreatingNewBot &&
      !isImportSaveBtnDisabled &&
      !webSocketIsInprogress
    ) {
      return (
        <StyledImportLabel>{'BOT is ready for import.'}</StyledImportLabel>
      );
    } else if (webSocketIsInprogress) {
      return <StyledImportLabel>{'Importing Bot...'}</StyledImportLabel>;
    } else if (isImportSaveBtnDisabled) {
      return (
        <StyledImportLabelError>
          {'Please select Answer Bank to proceed with Import.'}
        </StyledImportLabelError>
      );
    } else {
      return null;
    }
  };

  return (
    <Modal
      title={
        isMaxBotCountReached && isCreatingNewBot
          ? 'Bots limit reached'
          : botModalState?.action
          ? capitalize(`${botModalState.action} bot`)
          : ''
      }
      visible={isBotModalVisible}
      okText={isImportReady ? 'Save' : 'Import'}
      isFormModal
      width="60vw"
      spinning={loading}
      onOk={handleModalSubmit}
      okButtonProps={{
        disabled:
          isMaxBotCountReached || loading
            ? false
            : isImportReady && isImporting
            ? false
            : !botName,
        style: { display: `${isMaxBotCountReached ? 'none' : 'inline-block'}` },
      }}
      onCancel={handleCloseBotModal}
      role="add-new-bot-modal"
      destroyOnClose
      footer={
        !isMaxBotCountReached || (isMaxBotCountReached && isEditingBot) ? (
          <StyledBotModalFooter
            className={`${isCreatingNewBot ? 'showImport' : ''}`}
          >
            {renderLeftFooterContent(isImporting)}
            <StyledActionButtons>
              <Button
                disabled={loading || webSocketIsInprogress}
                onClick={handleCloseBotModal}
                value="Close"
                variant="link"
              />
              <StyledFooterSubmit
                variant="primary"
                value={
                  !isImporting || (isImporting && importStep)
                    ? 'Save'
                    : 'Import'
                }
                onClick={handleModalSubmit}
                disabled={
                  loading ||
                  (isCreatingNewBot && isMaxBotCountReached) ||
                  (!isImportReady && isImporting) ||
                  (!botName && !isImporting) ||
                  isImportSaveBtnDisabled ||
                  webSocketIsInprogress
                }
              />
            </StyledActionButtons>
          </StyledBotModalFooter>
        ) : null
      }
      {...rest}
    >
      {isMaxBotCountReached && isCreatingNewBot
        ? maxBotCountReachedMessage()
        : renderAddBotForm(isImporting)}

      {warnMsg && (
        <Alert
          style={{ margin: '10px 0 0 0' }}
          type="warning"
          message={warnMsg}
        />
      )}
      {exportOptions?.length > 0 && ExportOptionSelect()}
    </Modal>
  );
};

BotModal.propTypes = {
  bot: PropTypes.object,
  disabled: PropTypes.bool,
  onCancel: PropTypes.func,
  handleCreateNewBot: PropTypes.func,
  onOk: PropTypes.func,
  title: PropTypes.string,
  visibility: PropTypes.bool,
  onChangeBotName: PropTypes.func,
  onChangeBotDesc: PropTypes.func,
  action: PropTypes.string,
};

export default BotModal;
