import React, { useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Dropdown, Menu } from 'antd';
import {
  SettingOutlined,
  PlusOutlined,
  RadarChartOutlined,
  ExclamationCircleOutlined,
  GlobalOutlined,
  FileTextOutlined,
  ProfileOutlined,
} from '@ant-design/icons';

import Button from 'components/Button';
import SettingsDropdownMenu from '../SettingsDropdownMenu/SettingsDropdownMenu';
import { StyledTabActionButtons, StyledButton } from './TabsExtraActions.style';
import { Context } from 'store/store';
import { SET_CATEGORY_MODAL_OPEN } from 'store/action';
import useSelector from 'store/useSelector';
import {
  allAnswersSelector,
  isMaxAnswersSelector,
} from 'selectors/bot/answers';
import { allCategoriesSelector } from 'selectors/bot/categories';
import ToolTip from 'components/ToolTips/BaseToolTip';
import { botModeSelector } from 'selectors/bot';
import { BOT_MODES } from 'constants/bot';
import AnswerMenu from '../AnswerMenu/AnswerMenu';
import OpenAIAnswerMenu from '../AnswerMenu/OpenAIAnswerMenu';
import WebsiteMenu from '../AnswerMenu/WebsiteMenu';
const { SubMenu } = Menu;

const TabsExtraActions = ({
  filteredAnswers,
  handleOpenExportModal,
  isExportImportEnabled,
  selectedAnswers,
  handleShowDigraph,
  synced,
  handleManualBotSyncing,
  type,
  handleOpenFileModal,
  handleOpenAnswerModal,
  handleOpenAddAnswerModal,
}) => {
  const [, dispatch] = useContext(Context);
  const answers = useSelector(allAnswersSelector);
  const botMode = useSelector(botModeSelector);
  const limitReached = useSelector(isMaxAnswersSelector);
  const categories = useSelector(allCategoriesSelector);
  const isWebsite = useMemo(() => type === 'websites', [type]);

  const DropdownSettings = () => (
    <Dropdown
      overlay={
        <SettingsDropdownMenu
          answers={answers}
          categories={categories}
          filteredAnswers={filteredAnswers}
          handleOpenExportModal={handleOpenExportModal}
          isExportImportEnabled={isExportImportEnabled}
        />
      }
      disabled={!!selectedAnswers?.length}
      trigger={['click']}
    >
      <Button
        value={'Settings'}
        startIcon={<SettingOutlined />}
        variant="link"
        ghost
      />
    </Dropdown>
  );
  return (
    <StyledTabActionButtons mode="horizontal">
      {!limitReached && (
        <Menu.Item>
          {botMode === BOT_MODES.ZSB &&
          isExportImportEnabled &&
          type === 'answers' ? (
            <SubMenu
              key="subMenuZsbAnswers"
              title="Add Answer"
              icon={<ProfileOutlined />}
            >
              <AnswerMenu
                handleOpenModal={handleOpenAnswerModal}
                handleOpenAddAnswerModal={handleOpenAddAnswerModal}
              />
            </SubMenu>
          ) : type === 'answers' ? (
            <SubMenu
              key="subMenuOpenAiAnswers"
              title="Add Answer"
              icon={<ProfileOutlined />}
            >
              <OpenAIAnswerMenu
                handleOpenModal={handleOpenAnswerModal}
                handleOpenAddAnswerModal={handleOpenAddAnswerModal}
              />
            </SubMenu>
          ) : null}
        </Menu.Item>
      )}

      {type === 'websites' && isWebsite && botMode === BOT_MODES.OPENAI && (
        <Menu.Item>
          <SubMenu
            key="zeroshotWebsiteSubmenu"
            title="Add Website"
            icon={<GlobalOutlined />}
          >
            <WebsiteMenu
              handleOpenFileModal={fileType =>
                handleOpenFileModal(fileType || 'url')
              }
            />
          </SubMenu>
        </Menu.Item>
      )}

      {type === 'files' && botMode === BOT_MODES.OPENAI && (
        <Menu.Item>
          <StyledButton
            type="button"
            startIcon={<FileTextOutlined />}
            value={`Import File`}
            variant="link"
            onClick={() => handleOpenFileModal('file')}
          />
        </Menu.Item>
      )}

      {!isWebsite && (
        <Menu.Item>
          <StyledButton
            disabled={!!selectedAnswers?.length}
            type="button"
            startIcon={<PlusOutlined />}
            value="New Category"
            onClick={() => {
              dispatch({
                type: SET_CATEGORY_MODAL_OPEN,
                payload: {
                  action: 'add',
                },
              });
            }}
            variant="link"
          />
        </Menu.Item>
      )}

      {!isWebsite && categories?.length && botMode === BOT_MODES.OPENAI ? (
        <Menu.Item>
          {' '}
          <DropdownSettings />
        </Menu.Item>
      ) : !isWebsite && (categories?.length || botMode !== BOT_MODES.OPENAI) ? (
        <Menu.Item>
          {' '}
          <DropdownSettings />
        </Menu.Item>
      ) : null}

      {botMode !== BOT_MODES.OPENAI && (
        <Menu.Item>
          {' '}
          <Button
            onClick={handleShowDigraph}
            value={'Graph View'}
            startIcon={<RadarChartOutlined />}
            variant="link"
            ghost
          />
        </Menu.Item>
      )}

      {!synced && botMode === BOT_MODES.OPENAI && (
        <Menu.Item>
          {' '}
          <ToolTip title="Click here to sync bot.">
            <Button
              onClick={handleManualBotSyncing}
              value={'Sync Bot'}
              startIcon={<ExclamationCircleOutlined />}
              variant="link"
              className="syncBtn"
              ghost
            />
          </ToolTip>
        </Menu.Item>
      )}
    </StyledTabActionButtons>
  );
};

TabsExtraActions.propTypes = {
  filteredAnswers: PropTypes.any,
  handleOpenExportModal: PropTypes.func,
  isExportImportEnabled: PropTypes.bool,
  isFreeUser: PropTypes.bool,
  selectedAnswers: PropTypes.any,
  handleShowDigraph: PropTypes.func,
  type: PropTypes.string,
  handleOpenFileModal: PropTypes.func,
  handleOpenAnswerModal: PropTypes.func,
  handleOpenAddAnswerModal: PropTypes.func,
};

export default TabsExtraActions;
