import React from 'react';
import PropTypes from 'prop-types';
import { Menu } from 'antd';
import { StyledMenu } from './AnswerMenu.style';

const WebsiteMenu = ({ handleOpenFileModal }) => {
  return (
    <StyledMenu>
      <Menu.Item key="websiteUrl" onClick={() => handleOpenFileModal('url')}>
        Import URL
      </Menu.Item>
      <Menu.Item key="json" onClick={() => handleOpenFileModal('website-json')}>
        Import .JSON
      </Menu.Item>
    </StyledMenu>
  );
};

WebsiteMenu.propTypes = {
  handleOpenFileModal: PropTypes.func,
};

export default WebsiteMenu;
