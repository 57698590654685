import { message } from 'antd';
import { useContext, useState } from 'react';
import _ from 'lodash';
import { Context } from 'store/store';
import { apiService } from 'services/api.service';
import { DEFAULT_ERROR_MESSAGE } from 'constants/error';

const useExportModal = ({ onClose, onloadSelected }) => {
  const [state, dispatch] = useContext(Context);
  const {
    bot: { jid },
    sentinel,
    token,
  } = state;

  const exportOptions = [
    { value: 'category', label: 'Category' },
    {
      label: 'Answer Bank',
      children: [
        { value: 'answer', label: 'Answers' },
        // { value: 'file', label: 'Files' },
        { value: 'website', label: 'Websites' },
      ],
    },
    {
      value: 'question',
      label: 'Question',
    },
    {
      value: 'testsuite',
      label: 'Testsuite',
    },
  ];
  const [selectExportType, setSelectedExportType] = useState(
    onloadSelected || []
  );
  const [loading, setLoading] = useState(false);

  const handleChangeExportType = (e, options) => {
    if (Array.isArray(e)) {
      const existingExportType = selectExportType.filter(
        type => !options.map(option => option.value).includes(type)
      );

      setSelectedExportType([...existingExportType, ...e]);
    } else {
      const filtered = selectExportType.filter(
        value => value !== e.target?.value
      );
      if (!e.target?.checked) {
        setSelectedExportType(filtered);
      } else {
        setSelectedExportType([...selectExportType, e.target.value]);
      }
    }
  };

  const handleDownloadData = async () => {
    setLoading(true);
    const params = {
      bots: [jid],
      category: selectExportType.includes('category'),
      answer: selectExportType.includes('answer'),
      question: selectExportType.includes('question'),
      testsuite: selectExportType.includes('testsuite'),
      website: selectExportType.includes('website'),
    };
    try {
      const resExport = await apiService.export(sentinel, jid, token, params);
      const fileData = JSON.stringify(resExport.data.report[0]);
      const blob = new Blob([fileData], { type: 'text/plain' });
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.download = 'zeroshotbot.json';
      link.href = url;
      link.click();
      setTimeout(() => {
        setLoading(false);
        message.success(`Successfully exported.`);
        onClose();
      }, 1000);
    } catch (err) {
      setLoading(false);
      onClose();
      return message.error(err.message || DEFAULT_ERROR_MESSAGE);
    }
  };

  return {
    exportOptions,
    selectExportType,
    loading,
    handleChangeExportType,
    handleDownloadData,
  };
};

export default useExportModal;
