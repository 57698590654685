import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { startCase } from 'lodash';
import { Checkbox, Dropdown, Menu, Row, Tag } from 'antd';
import {
  CopyOutlined,
  DeleteOutlined,
  LinkOutlined,
  HistoryOutlined,
  CaretDownFilled,
} from '@ant-design/icons';

import Tooltip from 'components/ToolTips/BaseToolTip';
import {
  Wrapper,
  StyledColumn,
  StyledCategory,
  StyledCategoryName,
  StyledCategoryDropdownIcon,
  StyledEdit,
  StyledMore,
  StyledAnswerType,
} from './Answer.styles';
import { DEFAULT_ANSWER_VERSION } from 'constants/answerbank/defaults';
import Button from 'components/Button';
import AnswerVersionsMenu from './AnswerVersionsMenu';
import useAnswerItem from './hooks';
import useSelector from 'store/useSelector';
import { AnswerRenderer } from 'components/AnswerRenderer';
import {
  isMobileViewSelector,
  standardFontSizeSelector,
} from 'selectors/layout';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';

const Answer = ({
  id,
  answer,
  defaultAnswer,
  onDelete,
  isSelected,
  handleShowLinkedQuestions,
  handleShowAskedQuestions,
  onSelectAnswer,
  onUpdateCategoryAnswer,
  showDisplayAnswer,
  visible,
  totalSelectedAnswers,
  isTitleRow,
  isWebsite,
  ...rest
}) => {
  const { pathname } = useLocation();
  const { hitcount, qlinks, text, categoryId } = answer;
  const {
    allCategories,
    currentCategory,
    handleAnswerDropdownVisibilityChange,
    handleShowAnswerEditorModal,
    handleShowFileEditorModal,
    handleShowWebsiteEditorModal,
    showAnswerVersionDropdown,
    showAnswersVersions,
    toggleShowMore,
  } = useAnswerItem({ categoryId });
  const isMobileView = useSelector(isMobileViewSelector);
  const fontSize = useSelector(standardFontSizeSelector);

  const isFinalAnswer =
    !answer?.version || answer?.version === DEFAULT_ANSWER_VERSION;

  const ANSWER_MENU_ITEMS = [
    {
      key: 'delete',
      label: 'Delete',
      icon: <DeleteOutlined />,
      onClick: onDelete,
      show: !defaultAnswer,
    },
    {
      key: 'clone',
      label: 'Clone Answer',
      icon: <CopyOutlined />,
      onClick: () => handleShowAnswerEditorModal(answer, 'clone'),
      show: !defaultAnswer && answer.type === 'answer',
    },
    {
      key: 'link',
      label: 'Linked Questions',
      icon: <LinkOutlined />,
      onClick: () => handleShowLinkedQuestions(answer),
      show: !!qlinks && !!qlinks?.length && !defaultAnswer,
    },
    {
      key: 'log',
      label: 'Asked Questions',
      icon: <HistoryOutlined />,
      onClick: () => handleShowAskedQuestions(answer?.jid),
      show: true,
    },
  ];

  const moreMenu = (
    <Menu>
      {ANSWER_MENU_ITEMS.map(
        item =>
          item.show && (
            <Menu.Item key={item.key} icon={item.icon} onClick={item.onClick}>
              {item.label}
            </Menu.Item>
          )
      )}
    </Menu>
  );

  const renderAnswerTitleColumn = () => {
    if (pathname?.includes('websites')) {
      return 'Website Url';
    } else if (pathname?.includes('files')) {
      return 'File Name';
    } else {
      return 'Answer';
    }
  };

  const categoryMenu = () => (
    <Menu>
      <>
        <Menu.Item
          key={`no-category`}
          style={{
            backgroundColor: 'transparent',
            color: '#000',
          }}
          onClick={e => onUpdateCategoryAnswer(answer, null)}
        >
          {'Select Category'}
        </Menu.Item>
        <>
          {(allCategories || []).map(category => (
            <Menu.Item
              key={category?.jid}
              style={{
                backgroundColor: category.color,
                color: '#fff',
              }}
              onClick={e => onUpdateCategoryAnswer(answer, category.jid)}
            >
              {category.name}
            </Menu.Item>
          ))}
        </>
      </>
    </Menu>
  );

  if (
    showAnswersVersions?.length &&
    !showAnswersVersions.includes(answer.version) &&
    !isTitleRow &&
    !defaultAnswer
  ) {
    return null;
  }

  return (
    <Wrapper
      isSelected={isSelected}
      id={id}
      className={classNames(
        defaultAnswer ? 'defaultAnswer' : '',
        {
          isTitleRow,
        },
        rest.className
      )}
      answer={text}
      visible={visible || !!defaultAnswer}
    >
      <React.Fragment>
        <Row
          style={{ width: '100%' }}
          gutter={[6, 6]}
          // justify="space-around"
          align="left"
        >
          <StyledColumn>
            <Checkbox
              onChange={onSelectAnswer}
              checked={isTitleRow ? totalSelectedAnswers : isSelected}
              value={id}
            />
          </StyledColumn>
          {isTitleRow ? (
            <>
              <StyledColumn
                xs={11}
                md={{ span: allCategories.length && !isWebsite ? 11 : 12 }}
                lg={{ span: allCategories.length && !isWebsite ? 10 : 14 }}
                xl={{ span: allCategories.length && !isWebsite ? 12 : 15 }}
                xxl={{ span: allCategories.length && !isWebsite ? 12 : 16 }}
                titleCell
                style={{ fontSize }}
              >
                {renderAnswerTitleColumn()}
              </StyledColumn>
              {!allCategories?.length || isWebsite ? null : (
                <StyledColumn
                  xs={{ span: 5 }}
                  md={{ span: 4 }}
                  lg={{ span: 5 }}
                  xl={{ span: 4 }}
                  titleCell
                  hidden={isMobileView}
                  style={{ fontSize }}
                >
                  {'Category'}
                </StyledColumn>
              )}
              <StyledColumn
                xs={{ span: 7 }}
                md={{ span: 5 }}
                lg={{ span: 6 }}
                xl={{ span: 6 }}
                titleCell
                hidden={isMobileView}
                style={{ fontSize, padding: '0' }}
              >
                <Dropdown
                  overlay={
                    <AnswerVersionsMenu
                      handleAnswerDropdownVisibilityChange={
                        handleAnswerDropdownVisibilityChange
                      }
                    />
                  }
                  visible={showAnswerVersionDropdown}
                  onVisibleChange={handleAnswerDropdownVisibilityChange}
                >
                  <Button
                    value={'Version'}
                    variant="white"
                    endIcon={<CaretDownFilled />}
                    style={{ fontSize, padding: '0' }}
                  />
                </Dropdown>
              </StyledColumn>
              <StyledColumn font={3}>&nbsp;</StyledColumn>
            </>
          ) : (
            <>
              <StyledColumn
                xs={11}
                md={{ span: allCategories.length && !isWebsite ? 11 : 12 }}
                lg={{ span: allCategories.length && !isWebsite ? 10 : 14 }}
                xl={{ span: allCategories.length && !isWebsite ? 12 : 15 }}
                xxl={{ span: allCategories.length && !isWebsite ? 12 : 16 }}
                style={{ fontSize }}
              >
                {isWebsite ? (
                  answer?.scrapingInfo?.pages[0]?.goto?.url
                ) : (
                  <AnswerRenderer
                    showInChatBubble={showDisplayAnswer}
                    showDisplayAnswer={
                      showDisplayAnswer || defaultAnswer || false
                    }
                    answer={answer}
                  />
                )}
              </StyledColumn>
              {allCategories.length && !isWebsite ? (
                <StyledColumn
                  xs={{ span: 7 }}
                  md={{ span: 4 }}
                  lg={{ span: 5 }}
                  xl={{ span: 4 }}
                  hidden={isMobileView}
                  style={{ fontSize }}
                >
                  <StyledCategory>
                    {!defaultAnswer && allCategories.length ? (
                      <Dropdown overlay={categoryMenu} trigger={['hover']}>
                        {currentCategory ? (
                          <StyledCategoryName
                            style={{ fontSize }}
                            currentCategory={currentCategory}
                          >
                            {currentCategory.name}
                            <StyledCategoryDropdownIcon />
                          </StyledCategoryName>
                        ) : (
                          <StyledCategoryName style={{ fontSize }}>
                            {'Select Category'}
                            <StyledCategoryDropdownIcon />
                          </StyledCategoryName>
                        )}
                      </Dropdown>
                    ) : null}
                  </StyledCategory>
                </StyledColumn>
              ) : null}
              {!defaultAnswer ? (
                <StyledColumn
                  md={{ span: 3 }}
                  lg={{ span: 2 }}
                  hidden={isMobileView}
                  style={{
                    fontSize,
                  }}
                >
                  <Tag
                    color={isFinalAnswer ? 'green' : 'red'}
                    style={{ fontSize }}
                  >
                    {answer?.version || DEFAULT_ANSWER_VERSION}
                  </Tag>
                </StyledColumn>
              ) : (
                <StyledColumn
                  sm={{ span: 5 }}
                  md={{ span: 2 }}
                  hidden={isMobileView}
                  style={{ fontSize }}
                />
              )}
              <StyledColumn
                xs={{ span: 4 }}
                md={{ span: 4 }}
                lg={{ span: 4 }}
                xl={{ span: 4 }}
                hidden={!isMobileView}
              />
              <StyledColumn
                xs={{ span: 4 }}
                md={{ span: 3 }}
                lg={{ span: 3 }}
                xl={{ span: 3 }}
                style={{
                  fontSize,
                  display: 'flex',
                  alignItems: 'end',
                }}
                lastCol
              >
                {hitcount ? (
                  <div className="hit-count">{`hits: ${hitcount}`}</div>
                ) : null}
                {!defaultAnswer && qlinks?.length > 0 ? (
                  <div className="link-questions">
                    {`linked questions: ${qlinks?.length}`}
                  </div>
                ) : null}
                <div className="action">
                  {!defaultAnswer && (
                    <Tooltip title="Edit">
                      <StyledEdit
                        onClick={() =>
                          answer.type === 'answer'
                            ? handleShowAnswerEditorModal(answer)
                            : answer.type === 'website'
                            ? handleShowWebsiteEditorModal(answer)
                            : handleShowFileEditorModal(answer)
                        }
                      />
                    </Tooltip>
                  )}
                  <Dropdown
                    overlay={moreMenu}
                    placement="bottomLeft"
                    trigger={['hover']}
                  >
                    <StyledMore onClick={toggleShowMore} />
                  </Dropdown>
                </div>
              </StyledColumn>
            </>
          )}
        </Row>
      </React.Fragment>
    </Wrapper>
  );
};

Answer.propTypes = {
  answer: PropTypes.object.isRequired,
  defaultAnswer: PropTypes.bool,
  id: PropTypes.string.isRequired,
  isSelected: PropTypes.bool,
  handleShowAnswerEditorModal: PropTypes.func,
  handleShowFileEditorModal: PropTypes.func,
  handleShowLinkedQuestions: PropTypes.func,
  handleShowAskedQuestions: PropTypes.func,
  onDelete: PropTypes.func,
  onSelectAnswer: PropTypes.func,
  onUpdateCategoryAnswer: PropTypes.func,
  showDisplayAnswer: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  visible: PropTypes.bool,
  totalSelectedAnswers: PropTypes.number,
  isTitleRow: PropTypes.bool,
  isWebsite: PropTypes.bool,
};

export { Answer };
