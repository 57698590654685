import { useState, useContext, useEffect, useMemo, useCallback } from 'react';
import { message } from 'antd';
import { orderBy } from 'lodash';
import { Context } from 'store/store';
import { apiService } from 'services/api.service';
import { stripUUID } from 'utils';
import { strippedString } from 'utils/stringManipulation';
import {
  ADD_ANSWER,
  BULK_MOVE_CATEGORY,
  CLEAR_DIGRAPH_DATA,
  SET_CATEGORY_MODAL_CLOSE,
  DELETE_ANSWERBANK,
  HIDE_DIGRAPH,
  SET_SHOW_ANSWER_VERSIONS,
  SHOW_DIGRAPH,
  SET_CATEGORY_MODAL_OPEN,
  OPEN_ANSWER_EDITOR_CREATE_MODE,
  UPDATE_BOT_SYNC,
  UPDATE_FILE,
  UPDATE_ANSWER,
} from 'store/action';
import {
  allAnswersSelector,
  allDefaultAnswersSelector,
  answersSelector,
  filesSelector,
  isCategoryModalOpenSelector,
  isMaxAnswersSelector,
  websitesSelector,
} from 'selectors/bot/answers';
import { allCategoriesSelector } from 'selectors/bot/categories';
import {
  DEFAULT_ANSWER_VERSION,
  CREATE_ANSWER_MANUAL_SOURCE,
  CREATE_ANSWER_MANUAL_SOURCE_TYPE,
} from 'constants/answerbank/defaults';
import { GET_DATA_ERROR } from 'constants/error';
import { importExportEnabledSelector } from 'selectors/plan';
import useSelector from 'store/useSelector';
import { getTokenSelector } from 'selectors/user';
import { categoryTemplatesSelector } from 'selectors/categoryTemplates';
import { convertToNewQuickReplyPayload } from 'utils/answers';
import { extractAnswerData } from 'store/reducers/helpers/bot/answers';
import { isAnObject } from 'utils/dataTypes';
import {
  botModeSelector,
  currentBotSelector,
  isPageReadySelector,
} from 'selectors/bot';
import { isANewBotSelector, isAnswerBankReadySelector } from 'selectors/bot/ui';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';
import { ANSWER_FILE_TYPES } from 'constants/answerbank/answertype';

const useAnswerBank = () => {
  const [state, dispatch] = useContext(Context);
  const allAnswers = useSelector(allAnswersSelector);
  const answers = useSelector(answersSelector);
  const files = useSelector(filesSelector);
  const websites = useSelector(websitesSelector);
  const allCategories = useSelector(allCategoriesSelector);
  const categoryTemplates = useSelector(categoryTemplatesSelector);
  const bot = useSelector(currentBotSelector);
  const isCategoryModalOpen = useSelector(isCategoryModalOpenSelector);
  const limitReached = useSelector(isMaxAnswersSelector);
  const token = useSelector(getTokenSelector);
  const isAnswerBankReady = useSelector(isAnswerBankReadySelector);
  const isDetailsPageReady = useSelector(isPageReadySelector);
  const botMode = useSelector(botModeSelector);
  const allDefaultAnswers = useSelector(allDefaultAnswersSelector);
  const isExportImportEnabled = useSelector(importExportEnabledSelector);
  const isANewBot = useSelector(isANewBotSelector);

  const { jid, name, synced } = bot;
  const {
    admin: { showDigraph },
    sentinel,
    graph,
    plan: {
      max_ans_count: maxAnswerCount,
      plan_type: planType,
      export_import,
      onboarding_flag,
    },
  } = state;

  const [answersOrder, setAnswersOrder] = useState({
    key: 0,
    sortBy: 'lastEdited',
  });
  const mainPath = '/answer-bank/';
  const { pathname } = useLocation();
  const [filteredAnswers, setFilteredAnswers] = useState([]);
  const [sending, setSending] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const [newAnswer, setNewAnswer] = useState('');
  const [selectedTab, setSelectedTab] = useState('all');
  const [showAskedQuestionModal, setShowAskedQuestionModal] = useState(false);
  const [questionLog, setQuestionLog] = useState([]);
  const [questionForTestSuite, setQuestionForTestSuite] = useState('');
  const [showAddQuestionToTestSuiteModal, setShowAddQuestionToTestSuiteModal] =
    useState(false);
  const [selectedAnswers, setSelectedAnswers] = useState([]);
  const [showBulkMoveCategoryModal, setShowBulkMoveCategoryModal] =
    useState(false);
  const [showBulkDeleteModal, setShowBulkDeleteModal] = useState(false);
  const [moveToCategory, setMoveToCategory] = useState(null);
  const [confirmModalLoading, setConfirmModalLoading] = useState(false);
  const [showDisplayAnswer, setShowDisplayAnswer] = useState(false);
  const [showAddPlainAnswerModal, setShowAddPlainAnswerModal] = useState(false);
  const [answerVersion, setAnswerVersion] = useState(DEFAULT_ANSWER_VERSION);
  const [isImportDropdownVisible, setIsImportDropdownVisible] = useState(false);
  const startDateInMS = new Date().setDate(new Date().getDate() - 1);
  const startDate = new Date(startDateInMS).toISOString();
  const todayInMS = new Date();
  const todayISO = new Date(todayInMS).toISOString();
  const [dateFilter, setDateFilter] = useState({
    startDate: startDate,
    endDate: todayISO,
  });
  const [selectedAnswerId, setSelectedAnswerId] = useState(null);
  const [askedQuestionModalLoading, setAskedQuestionModalLoading] =
    useState(false);
  const isAddAnswerDisabled = !newAnswer || sending || !answerVersion;
  const [openExportModal, setOpenExportModal] = useState(false);

  const onChangeNewAnswer = e => {
    setNewAnswer(e.target.value);
  };

  const path = useCallback(
    path => pathname?.includes(`${mainPath}${path}`),
    [pathname]
  );

  const getStringAnswerDuplicate = (input, id) => {
    const strippedInput = strippedString(input);
    return allAnswers.find(
      answer =>
        strippedString(answer.text) === strippedInput && answer.jid !== id
    );
  };

  const handleDownloadAnswers = answerList => {
    const jsonAnswer = answerList.map(item => {
      const quick_reply = convertToNewQuickReplyPayload(item);
      const itemCategory = item.categoryId
        ? allCategories.find(
            categoryItem => categoryItem.jid === item.categoryId
          )
        : null;

      return {
        text: item.text,
        show_text: item.show_text || item.text,
        qlinks: item.qlinks,
        category: itemCategory ? itemCategory.name : '',
        category_color: itemCategory ? itemCategory.color : '',
        quick_reply,
        callback: item.requestAgent || false,
        editor: item.editor,
        version: item.version || DEFAULT_ANSWER_VERSION,
      };
    });
    const fileData = JSON.stringify(jsonAnswer);
    const blob = new Blob([fileData], { type: 'text/plain' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.download = 'zeroshotbot-answers.json';
    link.href = url;
    link.click();
    setTimeout(() => {
      message.success(`Successfully exported ${answerList.length} answer(s)`);
    }, 1000);
  };

  const onAddAnswer = async skipSimilarCheck => {
    if (isAddAnswerDisabled) {
      return false;
    }
    setSending(true);
    const isAnswerExists = getStringAnswerDuplicate(newAnswer);
    try {
      if (limitReached) {
        throw new TypeError(
          'Maximum number of answers reached. Please upgrade your plan.'
        );
      }
      if (isAnswerExists) {
        throw new TypeError('Answer already exists.');
      }
      setSending(true);
      setPageLoading(true);

      const answerDetails = {
        displayAnswer: newAnswer,
        text: newAnswer,
      };

      const res = await apiService.createAnswer(
        sentinel,
        jid,
        {
          source: CREATE_ANSWER_MANUAL_SOURCE,
          source_type: CREATE_ANSWER_MANUAL_SOURCE_TYPE,
        },
        answerDetails,
        token,
        skipSimilarCheck,
        answerVersion
      );
      // TODO: apply updated answer data structure
      const answerData = res.data.report[0];
      if (Array.isArray(answerData)) {
        dispatch({
          type: OPEN_ANSWER_EDITOR_CREATE_MODE,
          payload: {
            show_text: newAnswer,
            text: newAnswer,
            similarAnswers: answerData,
          },
        });
        setShowAddPlainAnswerModal(false);
        setPageLoading(false);
      } else if (isAnObject(answerData)) {
        dispatch({
          type: ADD_ANSWER,
          payload: answerData,
        });
        setPageLoading(false);
        setNewAnswer('');
        setShowAddPlainAnswerModal(false);
        setAnswerVersion(DEFAULT_ANSWER_VERSION);
        message.success('Successfully added an answer.');
      }
    } catch (err) {
      setPageLoading(false);
      message.error(
        err.message || 'Unexpected error encountered while saving.'
      );
    }
    setSending(false);
  };

  const onAddDraftAnswer = async () => {
    setSending(true);
    const isAnswerExists = getStringAnswerDuplicate(newAnswer);
    try {
      if (limitReached) {
        throw new TypeError(
          'Maximum number of answers reached. Please upgrade your plan.'
        );
      }
      if (isAnswerExists) {
        throw new TypeError('Answer already exists.');
      }
      setSending(true);

      const answerdetails = {
        displayAnswer: newAnswer,
        text: newAnswer,
      };

      const res = await apiService.createAnswer(
        sentinel,
        jid,
        {
          source: CREATE_ANSWER_MANUAL_SOURCE,
          source_type: CREATE_ANSWER_MANUAL_SOURCE_TYPE,
        },
        answerdetails,
        token,
        true,
        'draft'
      );
      const answerData = res.data.report[0];
      if (Array.isArray(answerData)) {
        dispatch({
          type: OPEN_ANSWER_EDITOR_CREATE_MODE,
          payload: {
            show_text: newAnswer,
            text: newAnswer,
            similarAnswers: answerData,
          },
        });
        setNewAnswer('');
        setAnswerVersion(DEFAULT_ANSWER_VERSION);
      } else {
        dispatch({
          type: ADD_ANSWER,
          payload: answerData,
        });

        setNewAnswer('');
        setAnswerVersion(DEFAULT_ANSWER_VERSION);
        message.success('Successfully added draft answer.');
      }
    } catch (err) {
      message.error(
        err.message || 'Unexpected error encountered while saving.'
      );
    }
    setSending(false);
  };

  const onDelete = async answerId => {
    const type = filteredAnswers.find(answer => answer.jid === answerId)?.type;
    setPageLoading(true);
    try {
      if (!ANSWER_FILE_TYPES.includes(type)) {
        await apiService.deleteAnswer(sentinel, answerId, token);
      } else {
        await apiService.deleteFile(sentinel, answerId, token);
      }

      dispatch({
        type: DELETE_ANSWERBANK,
        payload: {
          answerId,
          type,
        },
      });
      setPageLoading(false);
    } catch (error) {
      setPageLoading(false);
      throw new Error(error.message);
    }
  };

  const handleShowAskedQuestions = async id => {
    setSelectedAnswerId(id);
    setPageLoading(!showAskedQuestionModal);
    setAskedQuestionModalLoading(true);
    try {
      const res = await apiService.getQuestionLog(
        sentinel,
        jid,
        [id],
        token,
        dateFilter.startDate,
        dateFilter.endDate
      );

      if (!res.data.success || !res.data.report || !res.data.report[0]) {
        throw new Error('No records found!');
      }

      const askedQuestions = (res.data?.report[0]?.hits?.hits || []).map(
        data => {
          return {
            question: data?._source?.question,
            time: data?._source?.datetime,
          };
        }
      );

      setQuestionLog(askedQuestions);
      setShowAskedQuestionModal(true);
      setPageLoading(false);
      setAskedQuestionModalLoading(false);
    } catch (err) {
      setShowAskedQuestionModal(false);
      setPageLoading(false);
      setAskedQuestionModalLoading(false);
      message.error(err.message || GET_DATA_ERROR);
    }
  };

  const handleCloseAskedQuestionModal = () => {
    setQuestionLog([]);
    setSelectedAnswerId(null);
    setShowAskedQuestionModal(false);
    setDateFilter({
      startDate: startDate,
      endDate: todayISO,
    });
  };

  const handleShowAddTestSuiteModal = () => {
    setShowAskedQuestionModal(false);
    setShowAddQuestionToTestSuiteModal(true);
  };

  const handleCloseAddTestSuiteModal = () => {
    setShowAddQuestionToTestSuiteModal(false);
  };

  const renderFilterAnswer = () => {
    if (path('files')) {
      return files;
    } else if (path('websites')) {
      return websites;
    } else {
      return answers;
    }
  };

  useEffect(() => {
    const answerBankList = renderFilterAnswer();
    setSelectedTab('all');
    if (path('files')) {
      if (files.length) {
        setFilteredAnswers(
          orderBy(
            answerBankList,
            answersOrder.sortBy,
            answersOrder.key % 2 === 0 ? 'desc' : 'asc'
          )
        );
      }
    } else if (path('websites')) {
      if (websites.length) {
        setFilteredAnswers(
          orderBy(
            answerBankList,
            answersOrder.sortBy,
            answersOrder.key % 2 === 0 ? 'desc' : 'asc'
          )
        );
      }
    } else {
      if (answers.length) {
        setFilteredAnswers(
          orderBy(
            answerBankList,
            answersOrder.sortBy,
            answersOrder.key % 2 === 0 ? 'desc' : 'asc'
          )
        );
      }
    }
  }, [pathname, answers, files, websites]);

  const handleSortAnswers = item => {
    setAnswersOrder(item);
    const answerBankList = renderFilterAnswer();

    if (selectedTab === 'all') {
      setFilteredAnswers(
        orderBy(
          answerBankList,
          item.sortBy,
          item.key % 2 === 0 ? 'desc' : 'asc'
        )
      );
    } else {
      const answersByTab = answerBankList.filter(
        answerBank => answerBank.categoryId === selectedTab
      );
      setFilteredAnswers(
        orderBy(
          answersByTab,
          item?.sortBy,
          item?.key % 2 === 0 ? 'desc' : 'asc'
        )
      );
    }
  };

  const handleChangeTab = key => {
    setSelectedTab(key);
    const answerBankList = renderFilterAnswer();

    if (key === 'all') {
      setFilteredAnswers([...answerBankList]);
    } else {
      const filteredResult = answerBankList.filter(
        answer => stripUUID(answer.categoryId) === stripUUID(key)
      );
      setFilteredAnswers(filteredResult);
    }
    // setSearchKey(SEARCH_KEY_INITIAL_VALUE);
  };

  const handleChangeCheckbox = e => {
    if (e.target.checked) {
      setSelectedAnswers([...selectedAnswers, e.target.value]);
    } else {
      setSelectedAnswers(selectedAnswers.filter(ans => ans !== e.target.value));
    }
  };

  const handleSelectAll = e => {
    if (e.target.checked) {
      setSelectedAnswers(filteredAnswers.map(i => i.jid));
    } else {
      setSelectedAnswers([]);
    }
  };

  const handleBulkDelete = async () => {
    setConfirmModalLoading(true);
    await Promise.all(
      selectedAnswers.map(answerId => {
        return onDelete(answerId);
      })
    )
      .then(() => {
        message.success(
          `Successfully deleted ${selectedAnswers.length} answer(s).`
        );
        setConfirmModalLoading(false);
        setSelectedAnswers([]);
        return setShowBulkDeleteModal(false);
      })
      .catch(error => {
        setConfirmModalLoading(false);
        message.error(
          error.message || 'Something went wrong while deleting your answers.'
        );
      });
  };

  const exportSelectedAnswers = () => {
    const exportAnswers = filteredAnswers.filter(answer =>
      selectedAnswers.includes(answer.jid)
    );
    handleDownloadAnswers(exportAnswers);
    return setSelectedAnswers([]);
  };

  const handleShowDigraph = () => {
    dispatch({
      type: SHOW_DIGRAPH,
    });
  };

  const handleHideDigraph = () => {
    dispatch({
      type: HIDE_DIGRAPH,
    });
  };

  const handleCloseCategoryModal = () => {
    dispatch({
      type: SET_CATEGORY_MODAL_CLOSE,
    });
  };

  const handleChangeCategory = async (answer, categoryId) => {
    setPageLoading(true);
    try {
      if (ANSWER_FILE_TYPES.includes(answer.type)) {
        const res = await apiService.changeFile(
          sentinel,
          answer.jid || answer.id,
          token,
          { ...answer, categoryId: categoryId || '' }
        );
        if (res.data.success) {
          dispatch({
            type: UPDATE_FILE,
            payload: res.data.report[0],
          });
        }
      } else {
        const res = await apiService.changeAnswer(
          sentinel,
          answer.jid,
          answer.text,
          answer.show_html,
          categoryId,
          token,
          answer.editor,
          answer.quickReply,
          answer.version || DEFAULT_ANSWER_VERSION
        );
        const updatedAnswer = res.data?.report[0];

        if (updatedAnswer) {
          dispatch({
            type: UPDATE_ANSWER,
            payload: { updatedAnswer },
          });
        }
      }
      setPageLoading(false);
    } catch (error) {
      setPageLoading(false);
    }
  };

  const handleBulkMoveCategory = async () => {
    setConfirmModalLoading(true);
    setPageLoading(true);
    try {
      const updatedAnswers = selectedAnswers.map(async answerid => {
        const currentAnswer = allAnswers.find(i => i.jid === answerid);
        if (ANSWER_FILE_TYPES.includes(currentAnswer.type)) {
          const res = await apiService.changeFile(
            sentinel,
            currentAnswer.jid || currentAnswer.id,
            token,
            { ...currentAnswer, categoryId: moveToCategory || '' }
          );
          if (res.data.success) {
            return extractAnswerData(res.data.report[0]);
          }
        }
        const res = await apiService.changeAnswer(
          sentinel,
          currentAnswer.jid,
          currentAnswer.text,
          currentAnswer.show_text,
          moveToCategory,
          token,
          currentAnswer.editor,
          null
        );

        return extractAnswerData(res.data.report[0]);
      });

      await dispatch({
        type: BULK_MOVE_CATEGORY,
        payload: { updatedAnswers: await Promise.all(updatedAnswers) },
      });
    } catch (error) {
      message.error(
        error.message ||
          'Something went wrong while moving answer(s) to new category.'
      );
      setConfirmModalLoading(false);
      return setPageLoading(false);
    }
    setSelectedAnswers([]);
    setShowBulkMoveCategoryModal(false);
    setConfirmModalLoading(false);
    return setPageLoading(false);
  };

  const handleManualBotSyncing = async () => {
    setPageLoading(true);
    try {
      const res = await apiService.syncBot(sentinel, jid, token);
      if (!res.data.success) {
        throw new Error(GET_DATA_ERROR);
      }
      dispatch({
        type: UPDATE_BOT_SYNC,
        payload: {
          jid,
        },
      });
    } catch (error) {
      message.error(
        error.message ||
          'Something went wrong while moving answer(s) to new category.'
      );
      setConfirmModalLoading(false);
      return setPageLoading(false);
    }
    return setPageLoading(false);
  };

  useEffect(() => {
    return () => {
      setFilteredAnswers([]);
      handleHideDigraph();
      dispatch({ type: SET_SHOW_ANSWER_VERSIONS, payload: [] });
      dispatch({ type: CLEAR_DIGRAPH_DATA });
    };
  }, []);

  const handleToggleDropdown = () => {
    setIsImportDropdownVisible(!isImportDropdownVisible);
  };

  const handleDateFilterChange = (start_date, end_date) => {
    var validStartDate = new Date(
      new Date(start_date).setHours(0, 0, 0, 0)
    ).toISOString();
    var validEndDate = new Date(
      new Date(end_date).setHours(23, 59, 59, 59)
    ).toISOString();

    setDateFilter({
      startDate: validStartDate,
      endDate: validEndDate,
    });
  };

  const handleOpenExportModal = () => {
    setOpenExportModal(true);
  };

  const handleCloseExportModal = () => {
    setOpenExportModal(false);
  };

  const handleCloseAddAnswerModal = () => {
    setShowAddPlainAnswerModal(false);
    setNewAnswer('');
  };

  return {
    answerVersion,
    answersOrder,
    allAnswers,
    isANewBot,
    allCategories,
    categoryTemplates,
    confirmModalLoading,
    allDefaultAnswers,
    exportSelectedAnswers,
    export_import,
    filteredAnswers,
    jid,
    handleBulkDelete,
    handleBulkMoveCategory,
    handleChangeCheckbox,
    handleChangeTab,
    handleCloseAddTestSuiteModal,
    handleCloseAskedQuestionModal,
    handleCloseCategoryModal,
    handleDownloadAnswers,
    handleSelectAll,
    handleSortAnswers,
    handleShowAddTestSuiteModal,
    handleShowDigraph,
    handleHideDigraph,
    isCategoryModalOpen,
    limitReached,
    maxAnswerCount,
    moveToCategory,
    newAnswer,
    dateFilter,
    selectedAnswerId,
    askedQuestionModalLoading,
    onAddAnswer,
    onChangeNewAnswer,
    onDelete,
    pageLoading,
    planType,
    questionLog,
    questionForTestSuite,
    selectedAnswers,
    sending,
    showAddQuestionToTestSuiteModal,
    showAskedQuestionModal,
    showBulkDeleteModal,
    showBulkMoveCategoryModal,
    showDisplayAnswer,
    showDigraph,
    setShowAskedQuestionModal,
    setShowBulkMoveCategoryModal,
    setShowBulkDeleteModal,
    setShowDisplayAnswer,
    setMoveToCategory,
    setAnswerVersion,
    token,
    sentinel,
    graph,
    dispatch,
    onboarding_flag,
    handleToggleDropdown,
    isImportDropdownVisible,
    setIsImportDropdownVisible,
    isAnswerBankReady,
    handleDateFilterChange,
    handleShowAskedQuestions,
    onAddDraftAnswer,
    setNewAnswer,
    isAddAnswerDisabled,
    handleOpenExportModal,
    handleCloseExportModal,
    handleManualBotSyncing,
    openExportModal,
    synced,
    botMode,
    isDetailsPageReady,
    handleChangeCategory,
    setAnswerVersion,
    path,
    setAnswerVersion,
    showAddPlainAnswerModal,
    setShowAddPlainAnswerModal,
    handleCloseAddAnswerModal,
    selectedTab,
    isExportImportEnabled,
    renderFilterAnswer,
    setSelectedAnswers,
  };
};

export default useAnswerBank;
