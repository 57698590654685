import { Alert } from 'antd';
import styled from 'styled-components';

export const StyledModalErrorAlert = styled(Alert)`
  margin: 20px 0;
`;

export const ActionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 16px 0;
  color: ${props => (props?.error ? `red` : `rgba(0, 0, 0, 0.85)`)};
`;

export const StyledBtnAdvanceSettings = styled.button`
  display: flex;
  border: none;
  height: 38px;
  background: #ffff;
  color: #1667e7;
  border-radius: 1px;
  cursor: pointer;
  margin-top: 15px;
  float: right;
  line-height: 32px;

  &:hover {
    background: #1667e726;
  }
`;

export const ActionButtons = styled.div`
  display: flex;
  gap: 8px;
`;

export const ActiveActionLabel = styled.h3`
  color: #167be7 !important;
`;
