import React, { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Joyride, { ACTIONS, EVENTS, STATUS } from 'react-joyride';
import {
  CloseCircleOutlined,
  InfoCircleOutlined,
  MinusSquareOutlined,
  CloseSquareOutlined,
} from '@ant-design/icons';
import {
  Col,
  Input,
  Row,
  Spin,
  Statistic,
  Steps,
  Typography,
  message,
} from 'antd';
import TextArea from 'components/TextArea';
import Tooltip from 'components/ToolTips/BaseToolTip';
import Button from 'components/Button';
import Modal from 'components/Modals/GenericModal';
import KeywordsForm from './KeywordsForm';
import JoyrideTooltip from 'components/ToolTips/JoyrideToolTip';
import { AnswerList } from './AnswerList';
import ImportAlertMessage from './ImportAlertMessage';
import CsvOrJsonStep from './CsvOrJsonStep';
import TextIngestStep from './TextIngestStep';
import {
  MaxSentenceCountWrapper,
  KeywordsWrapper,
  StyledFooterWrapper,
  StyledUploadTip,
  StyledUploadTipTitle,
  StyledSimilarAnswersLabel,
  StyledAnswersItem,
  StyledAnswers,
  StyledSimilarAnswers,
  StyledSummaryCard,
  ProductNameWrapper,
  MaxAnswersCountWrapper,
  HeaderContainer,
  HeaderTitle,
  HeaderActions,
} from './ImportAnswerModal.style';
import useImportAnswerModal from './hooks';
import { apiService } from 'services/api.service';
import { TEXT_INGEST_STEP_ONE } from 'constants/joyride_steps/import_answer_modal';
import { SET_ONBOARDING_FLAG } from 'store/action';
import Select from 'components/Select';

const { Paragraph } = Typography;
const { Step } = Steps;

const SAMPLE_JSON_FORMAT = (
  <Paragraph>
    <pre>
      {`[
  {
      "text": "Sample plain answer text",
      "show_text": "New <b>answer</b> again for testing"
      "qlinks": ['hello', 'test question'],
      "category": 'test answers',
      "category_color": "#d3d3d3",
  },
  {
      "text": "Another sample answer text",
      "show_text": "<i>Another<i/> <b>answer</b> for testing"
      "qlinks": ['question link', 'show me another answer'],
      "category": 'test answers 2',
      "category_color": "#167be7",
  },
]`}
    </pre>
  </Paragraph>
);

const ImportAnswerModal = ({
  fileType,
  onClose,
  visibility,
  setShowUploadModal,
  notificationAPI,
  currentStep,
  setCurrentStep,
  answersToVerify,
  setAnswersToVerify,
  parsedFileAnswers,
  setParsedFileAnswers,
  similarAnswers,
  setSimilarAnswers,
  selectedSimilarAnswers,
  setSelectedSimilarAnswers,
  verifiedAnswers,
  setVerifiedAnswers,
  verifyAnswersProgress,
  isVerifyingAnswers,
  setIsVerifyingAnswers,
  isImportBtnDisabled,
  setIsImportBtnDisabled,
  duplicatesFoundInFile,
  setDuplicatesFoundInFile,
  notificationKey,
  setNotificationKey,
}) => {
  const {
    answersAllowed,
    importErrorMsg,
    importWarnMsg,
    similarAnswerWarnMsg,
    finalAnswerWarnMsg,
    ingestSelection,
    ingestionValue,
    maxSentences,
    modalBtnLoading,
    duplicatesFound,
    handleCancelImport,
    runVerifyAnswersInBackground,
    handleShowAnswerEditorModal,
    handleRadioChange,
    handleRemoveAnswer,
    handleRemoveFile,
    handleToggleAnswerEditor,
    handleStepChange,
    handleUploadFile,
    handleSubmitFile,
    handleSummarizeSource,
    generateAanswersViaGpt,
    handleGenerateFAQ,
    openAiTemplatePlaceholders,
    openAiFAQTemplatePlaceholders,
    handleDisplaySimilarAnswer,
    handleVerifyAnswer,
    handleClearData,
    handleCheckValidations,
    sentinel,
    setIngestionValue,
    textGenerateObject,
    setTextGenerateObject,
    setMaxSentences,
    setShowImportTip,
    handleCloseConfirmationModal,
    showImportTip,
    token,
    onboarding_flag,
    graph,
    dispatch,
    verificationBtnLoading,
    summaryCount,
    openConfirmationModal,
    setGenerateFAQObject,
    generateFAQObject,
    handleFindFAQquestion,
    handleVerifiedAnswers,
    handleCheckAnswer,
  } = useImportAnswerModal({
    fileType,
    onClose,
    visibility,
    setShowUploadModal,
    notificationAPI,
    currentStep,
    setCurrentStep,
    parsedFileAnswers,
    setParsedFileAnswers,
    similarAnswers,
    setSimilarAnswers,
    selectedSimilarAnswers,
    setSelectedSimilarAnswers,
    verifiedAnswers,
    setVerifiedAnswers,
    isVerifyingAnswers,
    isImportBtnDisabled,
    setIsImportBtnDisabled,
    setIsVerifyingAnswers,
    duplicatesFoundInFile,
    setDuplicatesFoundInFile,
    notificationKey,
    setNotificationKey,
  });
  const [runTour, setRunTour] = useState(false);
  const [stepIndex, setStepIndex] = useState(0);

  useEffect(() => {
    if (!onboarding_flag.includes('TextIngest')) {
      setTimeout(() => {
        setRunTour(true);
      }, 1000);
    }
    return () => setRunTour(false);
  }, [onboarding_flag]);

  const handleAnswerIsVerified = answer => {
    return (
      verifiedAnswers?.filter(verifiedAnswer => verifiedAnswer === answer)
        ?.length > 0
    );
  };

  const handleDuplicates = answer => {
    const answerDetails = answer.text ? answer.text : answer.replace('\r', '');
    return (
      duplicatesFound.filter(
        item => item.text.replace('\r', '') === answerDetails
      ).length ||
      duplicatesFoundInFile.filter(
        item => item.text.replace('\r', '') === answerDetails
      ).length
    );
  };

  const onClickCallback = async data => {
    const { action, index, type, status } = data;
    if (STATUS.FINISHED === status || STATUS.SKIPPED === status) {
      setRunTour(false);
      setStepIndex(0);
      if (!onboarding_flag.includes('TextIngest')) {
        await apiService.setOnboardingFlag(
          sentinel,
          token,
          graph,
          'TextIngest'
        );
        dispatch({ type: SET_ONBOARDING_FLAG, payload: 'TextIngest' });
      }
    } else if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      const stepIndex = index + (action === ACTIONS.PREV ? -1 : 1);
      setStepIndex(stepIndex);
    }
  };

  const getTextGenerateFormElements = (property, display, type) => {
    const elements = {
      number: (
        <MaxSentenceCountWrapper>
          <label htmlFor="text-generate-max-sentence">Max {display}</label>
          <Input
            name="text-generate-max-sentence"
            min={2}
            max={3}
            defaultValue={10}
            onChange={e => handleTextGenerateObject(property, e.target.value)}
            type="number"
            size="middle"
          />
        </MaxSentenceCountWrapper>
      ),
      tags: (
        <KeywordsWrapper>
          <label htmlFor="keywords">{display}</label>
          <KeywordsForm
            property={property}
            tags={
              textGenerateObject?.[property].length > 0
                ? textGenerateObject?.[property]
                : []
            }
            handleTextGenerateObject={handleTextGenerateObject}
          />
        </KeywordsWrapper>
      ),
      text: (
        <TextArea
          autoFocus
          onChange={e => handleTextGenerateObject(property, e.target.value)}
          placeholder="Enter instructions to generate answer."
          type="url"
          required
          value={textGenerateObject?.[property] || ''}
        />
      ),
    };

    return elements[type];
  };

  const getGenerateFAQFormElements = (property, title, type, options) => {
    const elements = {
      text: (
        <ProductNameWrapper>
          <span>{title}</span>
          <Input
            autoFocus
            onChange={e => handleGenerateFAQObject(property, e.target.value)}
            size="large"
            placeholder={`Enter${title}`}
            defaultValue={generateFAQObject?.[property]}
            required
          />
        </ProductNameWrapper>
      ),
      number: (
        <MaxAnswersCountWrapper>
          <label htmlFor="faq-max-answers">{title}</label>
          <Input
            name="faq-max-answers"
            min={2}
            max={100}
            defaultValue={10}
            onChange={e => handleGenerateFAQObject(property, e.target.value)}
            type="number"
            size="middle"
          />
        </MaxAnswersCountWrapper>
      ),
      dropdown: (
        <Select
          defaultValue={generateFAQObject?.[property]}
          onChange={e => handleGenerateFAQObject(property, e)}
          label={title}
          options={options}
        />
      ),
    };

    return elements[type];
  };

  const getTextGenerateForm = () => {
    let formElements = [];
    for (const property in openAiTemplatePlaceholders) {
      const { display, type } = openAiTemplatePlaceholders[property];
      formElements.push(getTextGenerateFormElements(property, display, type));
    }

    return formElements;
  };

  const getGenerateFAQForm = () => {
    let formElements = [];
    for (const property in openAiFAQTemplatePlaceholders) {
      const { title, type, options } = openAiFAQTemplatePlaceholders[property];
      formElements.push(
        getGenerateFAQFormElements(property, title, type, options)
      );
    }

    return formElements;
  };

  const handleTextGenerateObject = (property, value) => {
    setTextGenerateObject({
      ...textGenerateObject,
      [property]: value,
    });
  };

  const handleGenerateFAQObject = (property, value) => {
    setGenerateFAQObject({
      ...generateFAQObject,
      [property]: value,
    });
  };

  const stepContent = {
    csv: (
      <CsvOrJsonStep
        fileType={fileType}
        handleUploadFile={handleUploadFile}
        handleRemoveFile={handleRemoveFile}
      />
    ),
    json: (
      <CsvOrJsonStep
        fileType={fileType}
        handleUploadFile={handleUploadFile}
        handleRemoveFile={handleRemoveFile}
      />
    ),
    'text-ingest': (
      <TextIngestStep
        ingestSelection={ingestSelection}
        handleRadioChange={handleRadioChange}
        setIngestionValue={setIngestionValue}
        ingestionValue={ingestionValue}
        maxSentences={maxSentences}
        setMaxSentences={setMaxSentences}
      />
    ),
    'text-generate': getTextGenerateForm(),
    'generate-faq': getGenerateFAQForm(),
  };

  const ImportStep = () => {
    return (
      <>
        <ImportAlertMessage
          importErrorMsg={importErrorMsg}
          importWarnMsg={importWarnMsg}
          similarAnswerWarnMsg={similarAnswerWarnMsg}
          finalAnswerWarnMsg={finalAnswerWarnMsg}
        />
        <StyledUploadTip>
          <StyledUploadTipTitle>
            {fileType === 'json' ? (
              <Tooltip
                title={
                  showImportTip
                    ? 'Hide file format'
                    : 'Show accepted file format'
                }
              >
                {showImportTip ? (
                  <CloseCircleOutlined
                    alt="Hide file format"
                    onClick={() => setShowImportTip(false)}
                  />
                ) : (
                  <InfoCircleOutlined
                    alt="Show accepted file format"
                    onClick={() => setShowImportTip(true)}
                  />
                )}
              </Tooltip>
            ) : null}
          </StyledUploadTipTitle>
          {showImportTip && fileType === 'json' && SAMPLE_JSON_FORMAT}
        </StyledUploadTip>
        {stepContent[fileType]}
      </>
    );
  };

  const SimilarAnswerList = useCallback(similarAnswers => {
    return (
      <StyledSimilarAnswers>
        <StyledSimilarAnswersLabel>Similar Answers:</StyledSimilarAnswersLabel>
        {similarAnswers?.answerData &&
          similarAnswers?.answerData?.map((similarAnswer, idx) => (
            <StyledAnswersItem key={idx}>
              <StyledAnswers>{similarAnswer.answer.context.text}</StyledAnswers>
            </StyledAnswersItem>
          ))}
      </StyledSimilarAnswers>
    );
  }, []);

  const toggleCheckBox = e => {
    if (e.target.checked) {
      setAnswersToVerify(parsedFileAnswers);
    } else {
      setAnswersToVerify([]);
    }
  };

  const toggleListCheckbox = e => {
    if (e.target.checked) {
      setAnswersToVerify([...answersToVerify, e.target.value]);
    } else {
      setAnswersToVerify([]);
      const newAnswers = answersToVerify.filter(
        answer => answer.uuid !== e.target.value.uuid
      );
      setAnswersToVerify(newAnswers);
    }
  };

  const getSimilarAnswer = useCallback(
    answer => {
      const similarAnswer = selectedSimilarAnswers.find(
        a => a.answer === handleCheckAnswer(answer)
      );
      if (similarAnswer) {
        return SimilarAnswerList(similarAnswer);
      } else {
        return null;
      }
    },
    [SimilarAnswerList, handleCheckAnswer, selectedSimilarAnswers]
  );

  const stepTitle = {
    csv: 'Import',
    json: 'Import',
    'text-ingest': 'Source to summarize',
    'text-generate': 'Text Prompt',
    'generate-faq': 'Generate',
  };

  const modalTitle = {
    csv: 'Select a file (.txt, .csv)',
    json: 'Import Answer Bank (.json)',
    'text-ingest': 'Text Summarize',
    'text-generate': 'Text Generate',
    'generate-faq': 'Generate FAQ',
  };

  const steps = [
    {
      title: stepTitle[fileType],
      content: ImportStep,
    },
    {
      title: 'Verify your Answers',
      content: () => (
        <AnswerList
          toggleCheckBox={toggleCheckBox}
          selectedAnswers={answersToVerify}
          parsedFileAnswers={parsedFileAnswers}
          deleteAnswers={deleteAnswers}
          answersAllowed={answersAllowed}
          fileType={fileType}
          similarAnswers={similarAnswers}
          verifiedAnswers={verifiedAnswers}
          verifyAnswers={verifyAnswers}
          handleFindFAQquestion={handleFindFAQquestion}
          toggleListCheckbox={toggleListCheckbox}
          handleCheckAnswer={handleCheckAnswer}
          handleShowAnswerEditorModal={handleShowAnswerEditorModal}
          handleDisplaySimilarAnswer={handleDisplaySimilarAnswer}
          handleDuplicates={handleDuplicates}
          handleAnswerIsVerified={handleAnswerIsVerified}
          handleToggleAnswerEditor={handleToggleAnswerEditor}
          selectedSimilarAnswers={selectedSimilarAnswers}
          handleVerifyAnswer={handleVerifyAnswer}
          handleRemoveAnswer={handleRemoveAnswer}
          getSimilarAnswer={getSimilarAnswer}
          verifyAnswersProgress={verifyAnswersProgress}
          importErrorMsg={importErrorMsg}
          importWarnMsg={importWarnMsg}
          similarAnswerWarnMsg={similarAnswerWarnMsg}
          finalAnswerWarnMsg={finalAnswerWarnMsg}
        />
      ),
    },
  ];

  const handleNextClick = () => {
    const importActions = {
      csv: handleCheckValidations,
      json: handleCheckValidations,
      'text-ingest': handleSummarizeSource,
      'text-generate': generateAanswersViaGpt,
      'generate-faq': handleGenerateFAQ,
    };

    importActions[fileType]();
  };

  const handleNextStep = () => {
    setCurrentStep(currentStep + 1);
  };

  const wordCount = useCallback(prompt => {
    if (prompt) {
      return prompt.split(' ').length;
    }

    return 0;
  }, []);

  const getTextGenerateButtons = () => {
    const hasAnswer = parsedFileAnswers.length > 0;
    return (
      <>
        {hasAnswer ? (
          <>
            <Button
              className="next-step-button"
              variant="primary-btn-v2"
              value="Next"
              onClick={handleNextStep}
              loading={modalBtnLoading}
            />
            <Button
              className="next-step-button"
              variant="primary-btn-v2"
              value="Re-generate"
              onClick={handleNextClick}
              loading={modalBtnLoading}
            />
          </>
        ) : (
          <Button
            className="next-step-button"
            variant="primary-btn-v2"
            value="Generate"
            onClick={handleNextClick}
            loading={modalBtnLoading}
            disabled={wordCount(textGenerateObject?.prompt) < 5}
          />
        )}
      </>
    );
  };

  const getGenerateAnswerButtons = () => {
    let validForm = false;
    if (generateFAQObject) {
      validForm =
        Object.values(generateFAQObject).filter(fields => fields === '')
          ?.length === 0;
    }
    const hasAnswer = parsedFileAnswers.length > 0;
    return (
      <>
        {hasAnswer ? (
          <>
            <Button
              className="next-step-button"
              variant="primary-btn-v2"
              value="Next"
              onClick={handleNextStep}
              loading={modalBtnLoading}
              disabled={!validForm}
            />
            <Button
              className="next-step-button"
              variant="primary-btn-v2"
              value="Re-generate"
              onClick={handleNextClick}
              loading={modalBtnLoading}
              disabled={!validForm}
            />
          </>
        ) : (
          <Button
            className="next-step-button"
            variant="primary-btn-v2"
            value="Generate"
            onClick={handleNextClick}
            loading={modalBtnLoading}
            disabled={!validForm}
          />
        )}
      </>
    );
  };

  const getActionButtons = () => {
    return (
      <>
        {currentStep ? (
          <Button
            variant={'link'}
            value="Cancel"
            onClick={handleCancelImport}
          />
        ) : null}
        {!currentStep ? (
          <>
            {fileType === 'text-generate' ? (
              getTextGenerateButtons()
            ) : fileType === 'generate-faq' ? (
              getGenerateAnswerButtons()
            ) : (
              <Button
                className="next-step-button"
                variant="primary-btn-v2"
                value="Next"
                onClick={handleNextClick}
                loading={modalBtnLoading}
                disabled={
                  fileType === 'text-ingest' && !!ingestionValue
                    ? false
                    : fileType !== 'text-ingest' &&
                      parsedFileAnswers &&
                      parsedFileAnswers.length
                    ? false
                    : true
                }
              />
            )}
          </>
        ) : (
          <Button
            variant="primary-btn-v2"
            value="Submit"
            loading={modalBtnLoading}
            onClick={() => {
              handleSubmitFile(fileType);
            }}
            disabled={
              isImportBtnDisabled ||
              !answersAllowed ||
              duplicatesFound.length > 0 ||
              verificationBtnLoading ||
              duplicatesFoundInFile.length > 0
            }
          />
        )}
      </>
    );
  };

  const verifyAnswers = useCallback(() => {
    setIsVerifyingAnswers(true);
    Promise.all(
      answersToVerify.map(async answer => {
        const verifiedAnswers = await handleDisplaySimilarAnswer(
          handleCheckAnswer(answer),
          true
        );
        return verifiedAnswers;
      })
    ).then(res => {
      message.success('No similar answer found.');
      handleVerifiedAnswers();
      setIsVerifyingAnswers(false);
    });
  }, [
    answersToVerify,
    handleCheckAnswer,
    handleDisplaySimilarAnswer,
    handleVerifiedAnswers,
    setIsVerifyingAnswers,
  ]);

  const deleteAnswers = () => {
    answersToVerify.forEach((answer, idx) => {
      handleRemoveAnswer(answer);
      // also remove selected answer after delete
      setAnswersToVerify(prevSelectedAnswers =>
        prevSelectedAnswers.filter(
          prevAnswer => prevAnswer.text === answer.text
        )
      );
    });
  };

  return (
    <>
      <Modal
        closable={false}
        visible={visibility}
        onCancel={handleCancelImport}
        onOk={() => handleSubmitFile(fileType)}
        okText="Submit"
        okButtonProps={{
          disabled: isImportBtnDisabled || !parsedFileAnswers?.length,
        }}
        cancelText={modalBtnLoading ? 'Close' : 'Cancel'}
        confirmLoading={modalBtnLoading}
        destroyOnClose={false}
        maskClosable={false}
        width="70%"
        footer={
          <StyledFooterWrapper>
            {currentStep ? (
              <Button value="Back" onClick={handleClearData} />
            ) : null}
            <div className="action-buttons">{getActionButtons()}</div>
          </StyledFooterWrapper>
        }
      >
        <HeaderContainer>
          <HeaderTitle>
            <h4>{modalTitle[fileType]}</h4>
          </HeaderTitle>
          <HeaderActions>
            {currentStep ? (
              <MinusSquareOutlined onClick={runVerifyAnswersInBackground} />
            ) : null}
            <CloseSquareOutlined onClick={handleCancelImport} />
          </HeaderActions>
        </HeaderContainer>
        <Spin
          spinning={
            (modalBtnLoading || verificationBtnLoading) &&
            verifiedAnswers.length === 0
          }
          tip="Just a moment..."
        >
          <Steps current={currentStep} onChange={handleStepChange}>
            {steps.map((step, idx) => (
              <Step key={idx} title={step.title} />
            ))}
          </Steps>
          <div className="steps-content" style={{ margin: '10px 0' }}>
            {steps[currentStep].content()}
          </div>
        </Spin>
      </Modal>
      <Modal
        visible={openConfirmationModal}
        onCancel={handleCloseConfirmationModal}
        cancelText="Close"
        destroyOnClose
      >
        <Row gutter={8}>
          <Col span={24}>
            <Typography.Title level={4} style={{ marginTop: 5 }}>
              Summary of added answers
            </Typography.Title>
          </Col>
          <Col span={12}>
            <StyledSummaryCard bordered={false}>
              <Statistic
                title="Final"
                value={summaryCount.final}
                valueStyle={{ color: '#3f8600' }}
                suffix="Answers"
              />
            </StyledSummaryCard>
          </Col>
          <Col span={12}>
            <StyledSummaryCard bordered={false}>
              <Statistic
                title="Draft"
                value={summaryCount.draft}
                valueStyle={{ color: '#cf1322' }}
                suffix="Answers"
              />
            </StyledSummaryCard>
          </Col>
        </Row>
      </Modal>
      <Joyride
        run={runTour}
        steps={TEXT_INGEST_STEP_ONE}
        stepIndex={stepIndex}
        tooltipComponent={JoyrideTooltip}
        continuous={true}
        debug
        spotlightClicks
        showProgress={true}
        showSkipButton={true}
        callback={onClickCallback}
        scrollToFirstStep={true}
        disableScrollParentFix={true}
        isFirstStep
      />
    </>
  );
};

ImportAnswerModal.propTypes = {
  fileType: PropTypes.string,
  onClose: PropTypes.func,
  show: PropTypes.bool.isRequired,
};

export default ImportAnswerModal;
