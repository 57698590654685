import { useState, useContext, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { message } from 'antd';
import { isInteger } from 'lodash';

import { Context } from 'store/store';
import { SET_BOT_SETTINGS, TOGGLE_SEND_CALLBACK_LOG } from 'store/action';
import { apiService } from 'services/api.service';
import { createDisplayAnswerAPIPayload } from 'utils/answers';
import { setBotSession, deleteBot } from 'services/bots.service';
import {
  importExportEnabledSelector,
  isOpenAIPlanEnabledSelector,
} from 'selectors/plan';
import ROUTES from 'constants/routes';
import { DEFAULT_ERROR_MESSAGE, GET_DATA_ERROR } from 'constants/error';
import {
  DEFAULT_EDITOR,
  DEFAULT_LANGUAGE,
  ZSB_CHAT_BREAKER_ENCONDING,
} from 'constants/answerbank/defaults';
import { testEmailRegEx } from 'utils';
import useSelector from 'store/useSelector';
import {
  autoTranslateResponseSelector,
  botModeSelector,
  botPersonaRetrievalCountSelector,
  botPersonaSelector,
  currentBotSelector,
  isBotMailConfigEnabledSelector,
  isBotOpenAIEnabledSelector,
} from 'selectors/bot';
import { allBotsSelector } from 'selectors/bots';
import { appendWithParenthesesIfDuplicate } from 'utils/stringManipulation';
import {
  topDefaultAnswerSelector,
  defaultAnswerThresholdSelector,
} from 'selectors/bot/answers';
import { BOT_MODES, DEFAULT_ANSWER_THRESHOLD } from 'constants/bot';

const useSettings = () => {
  const [state, dispatch] = useContext(Context);
  const importExportEnabled = useSelector(importExportEnabledSelector);
  const isBotOpenAIEnabled = useSelector(isBotOpenAIEnabledSelector);
  const isOpenAIPlanEnabled = useSelector(isOpenAIPlanEnabledSelector);
  const allBots = useSelector(allBotsSelector);
  const defaultAnswerThreshold = useSelector(defaultAnswerThresholdSelector);
  const defaultAnswer = useSelector(topDefaultAnswerSelector);
  const bot = useSelector(currentBotSelector);
  const botMode = useSelector(botModeSelector);
  const persona = useSelector(botPersonaSelector);
  const retrievalCount = useSelector(botPersonaRetrievalCountSelector);
  const autoTranslateResponse = useSelector(autoTranslateResponseSelector);

  const {
    bot: { name, jid, metadata, description, language },
    sentinel,
    token,
    graph,
    plan: { onboarding_flag },
  } = state;
  const [newDefaultAnswer, setNewDefaultAnswer] = useState(defaultAnswer);
  const [btnLoading, setBtnLoading] = useState(false);
  const minThreshold = 0.05;
  const maxThreshold = 0.3;
  const maxRetrievalCount = 10;
  const minRetrievalCount = 2;
  const [isThresholdLimitReached, setThresholdLimitReached] = useState(false);
  const [defaultAnswerEditor, setdefaultAnswerEditor] =
    useState(DEFAULT_EDITOR);
  const isBotMailConfigEnabled = useSelector(isBotMailConfigEnabledSelector);
  const [emailRecipients, setEmailRecipients] = useState('');
  const [hasInvalidEmail, setHasInvalidEmail] = useState(false);
  const [botName, setBotName] = useState(name);
  const [botDescription, setBotDescription] = useState(description);
  const [isConfirmDeleteBot, setIsConfirmDeleteBot] = useState(false);
  const [greetings, setGreetings] = useState(metadata.greetings || []);
  const [threshold, setThreshold] = useState(defaultAnswerThreshold);
  const [otherMetadata, setOtherMetadata] = useState({});
  const [isAddingNewResponse, setIsAddingNewResponse] = useState(false);
  const [newResponse, setNewResponse] = useState('');
  const [useOpenAI, setUseOpenAI] = useState(isBotOpenAIEnabled);
  const [translateLanguage, setTranslateLanguage] = useState(
    autoTranslateResponse
  );
  const [selectedLanguage, setSelectedLanguage] = useState(DEFAULT_LANGUAGE);
  const [openExportModal, setOpenExportModal] = useState(false);
  const [parsedAnswer, setParsedAnswer] = useState([]);
  const [botPersona, setBotPersona] = useState(persona);
  const [botRetrievalCount, setBotRetrievalCount] = useState(retrievalCount);
  const DEFAULT_EXPORT_DETAILS = [
    'answer',
    'question',
    'testsuite',
    'category',
    'website',
  ];
  const history = useHistory();

  const handleBotNameInput = e => {
    e.preventDefault();
    setBotName(e.target.value);
  };

  const handleBotDescriptionInput = e => {
    setBotDescription(e.target.value);
  };

  const handleResetToDefault = () => {
    setBotName(name);
    setBotDescription(description);

    if (metadata) {
      setGreetings(metadata.greetings || []);

      const others = metadata.others;
      if (others) {
        setOtherMetadata({
          ...others,
          language: metadata.others.language || DEFAULT_LANGUAGE,
        });
      } else {
        setOtherMetadata({
          language: DEFAULT_LANGUAGE,
        });
      }
    } else {
      setGreetings([]);
      setNewDefaultAnswer(defaultAnswer);
      setThreshold(defaultAnswer?.threshold || DEFAULT_ANSWER_THRESHOLD);
      setOtherMetadata({
        language: DEFAULT_LANGUAGE,
      });
    }
  };

  const toggleConfirmDeleteBot = () => {
    setIsConfirmDeleteBot(!isConfirmDeleteBot);
  };

  const handleDeleteBot = async () => {
    try {
      await deleteBot(sentinel, jid, token);
      await dispatch({ type: 'DELETE_BOT', payload: jid });
      setBotSession({});
      message.success('Bot successfully deleted.');
      history.push(ROUTES.BOTS_PAGE);
    } catch (err) {
      return message.error(
        typeof err !== 'string' && err.message !== DEFAULT_ERROR_MESSAGE
          ? err.message
          : GET_DATA_ERROR
      );
    }
  };

  const handleNewResponseInput = e => {
    setNewResponse(e.target.value);
  };

  const handleChangeThreshold = e => {
    setThreshold(Number(e.target.value));
  };

  useEffect(() => {
    if (threshold >= minThreshold && threshold <= maxThreshold) {
      setThresholdLimitReached(false);
    } else {
      setThresholdLimitReached(true);
    }
  }, [threshold]);

  const handleAddNewResponse = () => {
    if (newResponse.length) {
      setGreetings([...greetings, newResponse]);
      setNewResponse('');
      setIsAddingNewResponse(false);
    } else {
      message.error('New response should not be empty');
    }
  };

  const toggleAddNewResponse = () => {
    setIsAddingNewResponse(true);
  };

  const handleDeleteGreeting = idx => {
    const updatedGreetings = greetings.filter(
      (greeting, index) => index !== idx
    );
    setGreetings(updatedGreetings);
  };

  const updateFormattedAnswer = (value, plainText) => {
    setNewDefaultAnswer({
      ...createDisplayAnswerAPIPayload(value),
      text: plainText,
    });
  };

  const handleAnswerParsing = editedHTML => {
    if (!Array.isArray(editedHTML)) {
      if (
        !editedHTML?.includes('<img') &&
        !editedHTML?.includes(ZSB_CHAT_BREAKER_ENCONDING)
      ) {
        return setParsedAnswer([
          {
            type: 'text',
            value: editedHTML?.replace(/<[^>]*>/g, ''),
          },
        ]);
      } else {
        const splittedAnswer = editedHTML.split(ZSB_CHAT_BREAKER_ENCONDING);
        let parsedAnswerList = [];
        return splittedAnswer?.map(answer => {
          if (answer?.includes('<img')) {
            const htmlString = answer
              ?.replace('<strong><em>', '')
              ?.replace('</em></strong>', '');
            const tempElement = document.createElement('div');
            tempElement.innerHTML = htmlString;
            const childNodes = tempElement.childNodes;
            Array.from(childNodes)?.map(parentNode => {
              const childNode = parentNode.childNodes;
              if (childNode.length > 0) {
                Array.from(childNode)?.map(childNode => {
                  if (childNode.tagName === 'IMG') {
                    parsedAnswerList = [
                      ...parsedAnswerList,
                      {
                        type: 'image',
                        value: childNode.getAttribute('src'),
                      },
                    ];
                  } else {
                    parsedAnswerList = [
                      ...parsedAnswerList,
                      {
                        type: 'text',
                        value: childNode.textContent.replace(/<[^>]*>/g, ''),
                      },
                    ];
                  }
                });
              } else {
                parsedAnswerList = [
                  ...parsedAnswerList,
                  {
                    type: 'text',
                    value: parentNode.textContent.replace(/<[^>]*>/g, ''),
                  },
                ];
              }
            });
          } else {
            parsedAnswerList = [
              ...parsedAnswerList,
              {
                type: 'text',
                value: answer.replace(/<[^>]*>/g, ''),
              },
            ];
          }
          return setParsedAnswer(
            parsedAnswerList.filter(answer => answer.value !== '')
          );
        });
      }
    }
  };

  const handleSaveChanges = async () => {
    if (isThresholdLimitReached) {
      return message.error(
        `Answer threshold allowed is ${minThreshold} - ${maxThreshold}`
      );
    }
    try {
      // replace => remove extra special chars
      // split => convert string of emails to array
      const recipients = emailRecipients
        .replace(/^[^a-z\d]*|[^a-z\d]*$/gi, '')
        .split(',')
        .map(email => email.trim());

      if (isBotMailConfigEnabled && recipients && emailRecipients.length) {
        const test = recipients.every(email => testEmailRegEx(email));
        if (!test) {
          setHasInvalidEmail(true);
          return message.error('Incorrect email recipient found!');
        }
      }

      if (isBotMailConfigEnabled && !emailRecipients.length) {
        setHasInvalidEmail(true);
        return message.error('Callback Email address field is required.');
      }

      const mailConfig = {
        enabled: isBotMailConfigEnabled,
        recipients: emailRecipients ? [...recipients] : [],
      };

      const metadata = {
        greetings,
        others: otherMetadata,
      };

      const botLanguageConfig = {
        language: selectedLanguage,
        use_openai: useOpenAI,
        auto_translate_response: translateLanguage,
        max_candidate: Number(botRetrievalCount || retrievalCount),
        persona: botPersona || '',
      };

      const botNameToSubmit = appendWithParenthesesIfDuplicate(
        allBots.map(bot => bot.name && bot.jid === jid),
        botName
      );
      const res = await apiService.editBot(
        sentinel,
        jid,
        botNameToSubmit,
        botMode,
        botDescription,
        token,
        metadata,
        mailConfig,
        botLanguageConfig,
        newDefaultAnswer || defaultAnswer,
        threshold
      );
      if (!res.data || !res.data.report[0] || !res.data.report[0].context) {
        throw new Error(GET_DATA_ERROR);
      }

      const botData = res.data.report[0];
      const defaultAnswerData = res.data.report[1];

      dispatch({
        type: SET_BOT_SETTINGS,
        payload: {
          bot: botData,
          defaultAnswer: defaultAnswerData,
        },
      });

      message.success('Bot settings are saved successfully.');
    } catch (err) {
      return message.error(err.message || DEFAULT_ERROR_MESSAGE);
    }
  };

  const handleOpenExportModal = () => {
    setOpenExportModal(true);
  };

  const handleCloseExportModal = () => {
    setOpenExportModal(false);
  };

  const handleChangeEmailRecipentsInput = e => {
    if (hasInvalidEmail) {
      setHasInvalidEmail(false);
    }
    setEmailRecipients(e.target.value);
  };

  const isSettingsFormsHasError = useMemo(
    () =>
      isThresholdLimitReached ||
      ((!isInteger(botRetrievalCount) ||
        Number(botRetrievalCount) > maxRetrievalCount ||
        Number(botRetrievalCount) < minRetrievalCount) &&
        botMode === BOT_MODES.OPENAI),
    [isThresholdLimitReached, botRetrievalCount]
  );

  useEffect(() => {
    setBotName(name);
    setBotDescription(description);
    setUseOpenAI(isBotOpenAIEnabled);
    setSelectedLanguage(language || DEFAULT_LANGUAGE);
    const answer =
      defaultAnswer.show_html || defaultAnswer.show_text || defaultAnswer.text;
    const parsedRichTextAnswer = Array.isArray(answer)
      ? answer.join(ZSB_CHAT_BREAKER_ENCONDING)
      : answer;
    handleAnswerParsing(parsedRichTextAnswer);
    if (bot?.mailConfig?.recipients) {
      setEmailRecipients(bot?.mailConfig?.recipients.toString());
    }
    if (metadata) {
      const others = metadata.others;
      if (others) {
        setOtherMetadata({
          ...others,
          language: metadata.others.language || DEFAULT_LANGUAGE,
        });
      } else {
        setOtherMetadata({
          language: DEFAULT_LANGUAGE,
        });
      }
    }
  }, [bot, description, metadata, name]);

  const toggleSendCallbackLog = () => {
    dispatch({
      type: TOGGLE_SEND_CALLBACK_LOG,
    });
  };

  return {
    botName,
    botDescription,
    btnLoading,
    defaultAnswerEditor,
    dispatch,
    emailRecipients,
    isConfirmDeleteBot,
    graph,
    greetings,
    isThresholdLimitReached,
    isAddingNewResponse,
    importExportEnabled,
    handleBotNameInput,
    handleBotDescriptionInput,
    handleResetToDefault,
    toggleConfirmDeleteBot,
    handleDeleteBot,
    handleNewResponseInput,
    handleChangeThreshold,
    handleAddNewResponse,
    hasInvalidEmail,
    toggleAddNewResponse,
    handleDeleteGreeting,
    handleSaveChanges,
    handleChangeEmailRecipentsInput,
    useOpenAI,
    setUseOpenAI,
    minThreshold,
    maxThreshold,
    newDefaultAnswer,
    newResponse,
    onboarding_flag,
    otherMetadata,
    sentinel,
    setdefaultAnswerEditor,
    setSelectedLanguage,
    updateFormattedAnswer,
    setTranslateLanguage,
    threshold,
    token,
    selectedLanguage,
    translateLanguage,
    isOpenAIPlanEnabled,
    openExportModal,
    DEFAULT_EXPORT_DETAILS,
    handleOpenExportModal,
    handleCloseExportModal,
    botMode,
    jid,
    setBotPersona,
    setBotRetrievalCount,
    botPersona,
    botRetrievalCount,
    isSettingsFormsHasError,
    toggleSendCallbackLog,
    isBotMailConfigEnabled,
  };
};

export default useSettings;
