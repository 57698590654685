import React from 'react';
import PropTypes from 'prop-types';
import { Menu } from 'antd';
import { StyledMenu } from './AnswerMenu.style';

const OpenAIAnswerMenu = ({ handleOpenModal, handleOpenAddAnswerModal }) => {
  return (
    <StyledMenu>
      <Menu.Item key="planText" onClick={handleOpenAddAnswerModal}>
        {`Plain Text`}
      </Menu.Item>
      <Menu.Item key="json" onClick={() => handleOpenModal('json')}>
        Import .JSON
      </Menu.Item>
    </StyledMenu>
  );
};

OpenAIAnswerMenu.propTypes = {
  handleOpenModal: PropTypes.func,
  handleOpenAddAnswerModal: PropTypes.func,
};

export default OpenAIAnswerMenu;
