import { orderBy } from 'lodash';

import {
  ADD_ANSWER,
  ADD_ANSWER_QLINK,
  ADD_MULTIPLE_ANSWERS,
  BULK_DELETE_ANSWERS,
  DELETE_ANSWERBANK,
  SET_ANSWERS,
  REMOVE_ANSWER_QLINK,
  SET_DEFAULT_ANSWER,
  SET_SHOW_ANSWER_VERSIONS,
  SET_SHOW_BELOW_THRESHOLD_RESPONSES,
  SET_SHOW_DRAFT_RESPONSES,
  UPDATE_ANSWER,
  UPDATE_ANSWER_HITCOUNT,
  ADD_FILE,
  UPDATE_FILE,
  RESET_WS_GENERATED_ANSWER,
  SET_WS_ASK_QUESTION_ACTION,
  SET_WS_ASK_QUESTION_SENDING,
  SET_WS_SCRAPER_ACTION,
  RESET_WS_SCANNER,
  WS_ADD_FILE,
  RESET_WS_ADD_FILE_ADDED,
  WS_UPDATE_ACTION,
  UPDATE_WS_SCANNED_DATA_SOURCE,
  WS_STOP_SCRAPER,
  UPDATE_WEBSITE,
  SET_WEBSITE_SELECTED_PAGES,
  RESET_WEBSITE_SELECTED_PAGES,
} from 'store/action';
import {
  extractAnswerData,
  extractDefaultAnswer,
  extractFileData,
  extractGeneratedAnswer,
  extractScannedObject,
  categorizedWebsitePages,
  extractAnswerList,
} from '../helpers/bot/answers';
import { extractCategoryData } from '../helpers/bot/categories';
import { BOT_MODES } from 'constants/bot';
import { getNodesFromArray } from 'utils';
import { initialWebsiteSelectedPage } from 'store/initialState';

export const answersReducer = (state, action) => {
  const synced = state.bot.mode !== BOT_MODES.ZSB ? false : state.bot.synced;
  switch (action.type) {
    case SET_ANSWERS: {
      const { answers, files, defaultAnswer } = action.payload;
      const isBotOpenAIEnabled = state.bot?.useOpenAI;

      const defaultAnswerData = (defaultAnswer || []).map(answerItem => {
        return extractDefaultAnswer(answerItem.context, answerItem.jid);
      });

      const botWithAnswers = Object.assign(state.bot, {
        answers: extractAnswerList(answers, isBotOpenAIEnabled),
        files: extractAnswerList(
          files?.filter(
            file =>
              !file.context.scraping_info && file.context.scraping_info === null
          ),
          isBotOpenAIEnabled
        ),
        websites: extractAnswerList(
          files
            ?.filter(
              file =>
                file.context.scraping_info &&
                file.context.scraping_info !== null
            )
            ?.map(website => ({
              ...website,
              name: 'website',
            })),
          isBotOpenAIEnabled
        ),
        defaultAnswer: [...state.bot?.defaultAnswer, ...defaultAnswerData],
      });

      return {
        ...state,
        bot: {
          ...state.bot,
          ...botWithAnswers,
          ui: {
            ...state.bot.ui,
            isAnswerBankReady: true,
          },
        },
      };
    }

    case ADD_ANSWER: {
      const isBotOpenAIEnabled = state.bot?.useOpenAI;
      const newAnswer = extractAnswerData(action.payload, isBotOpenAIEnabled);
      const updatedBots = state.bots.map(bot => {
        if (bot.jid === state.bot.jid) {
          return {
            ...bot,
            answerCount: state.bot.answerCount + 1,
          };
        }
        return bot;
      });

      return {
        ...state,
        bots: updatedBots,
        bot: {
          ...state.bot,
          answerCount: state.bot.answerCount + 1,
          answers: [newAnswer, ...state.bot.answers],
          synced,
        },
      };
    }

    case ADD_FILE: {
      const newFiles = extractFileData(action.payload.response);
      const updatedBots = state.bots.map(bot => {
        if (bot.jid === state.bot.jid) {
          return {
            ...bot,
            fileCount:
              action.payload.type === 'file'
                ? newFiles.length + state.bot.fileCount
                : state.bot.fileCount,
            websiteCount:
              action.payload.type === 'website'
                ? newFiles.length + state.bot.websiteCount
                : state.bot.websiteCount,
          };
        }
        return bot;
      });
      return {
        ...state,
        bots: updatedBots,
        bot: {
          ...state.bot,
          fileCount:
            action.payload.type === 'file'
              ? newFiles.length + state.bot.fileCount
              : state.bot.fileCount,
          websiteCount:
            action.payload.type === 'website'
              ? newFiles.length + state.bot.websiteCount
              : state.bot.websiteCount,
          [action.payload.type + 's']: [
            ...newFiles,
            ...state.bot[action.payload.type + 's'],
          ],
          synced,
        },
        webSocket: {
          ...state.webSocket,
          scannedTableDataSource: [],
          categorizedTableDataSource: [],
        },
      };
    }

    case ADD_MULTIPLE_ANSWERS: {
      const { answer, category } = action.payload;
      const isBotOpenAIEnabled = state.bot?.useOpenAI;
      const isNewImporterResponse = answer && category;
      const newAnswers = isNewImporterResponse
        ? extractAnswerData(answer, isBotOpenAIEnabled)
        : extractAnswerData(action.payload, isBotOpenAIEnabled);
      const newCategories = category ? extractCategoryData(category) : [];
      const updatedBots = state.bots.map(bot => {
        if (bot.jid === state.bot.jid) {
          return {
            ...bot,
            answerCount: newAnswers.length + state.bot.answers.length,
          };
        }
        return bot;
      });

      return {
        ...state,
        bots: updatedBots,
        bot: {
          ...state.bot,
          answers: [...newAnswers, ...state.bot.answers],
          categories: [...newCategories, ...state.bot.categories],
          synced,
        },
      };
    }

    case UPDATE_ANSWER: {
      let answersArr = [];
      const { chatList } = state.bot;
      const isBotOpenAIEnabled = state.bot?.useOpenAI;
      const updatedChatList = [];
      let currentChatItem = {};
      const { updatedAnswer, msgIndex } = action.payload;

      const answerData = extractAnswerData(
        getNodesFromArray(updatedAnswer),
        isBotOpenAIEnabled
      );

      for (const item of state.bot.answers) {
        if (item.jid === answerData.jid) {
          answersArr.push(answerData);
        } else {
          answersArr.push(item);
        }
      }

      if (chatList.length) {
        for (let i = 0; i < chatList?.length; i++) {
          if (chatList[i].answer.jid === answerData.jid && msgIndex === i) {
            currentChatItem = {
              question: chatList[i].question,
              answer: {
                jid: answerData.jid,
                show_text: answerData.show_text,
                show_html: answerData.show_html,
                text: answerData.text,
                qlinks: answerData.qlinks || null,
                score: answerData.score || null,
                hitcount: answerData.hitcount || null,
                editor: answerData.editor || null,
                type: answerData.type,
                sequence: answerData.sequence || [],
              },
              idx: i,
            };
            updatedChatList.push(currentChatItem);
          } else {
            updatedChatList.push({ ...chatList[i] });
          }
        }
      }

      return {
        ...state,
        bot: {
          ...state.bot,
          answers: [...answersArr],
          chatList: updatedChatList,
          synced,
        },
      };
    }

    case UPDATE_FILE: {
      const answerData = extractAnswerData(action.payload, true);
      const newFiles = state.bot.files.map(files => {
        if (answerData.jid === files.jid) {
          return answerData;
        }
        return files;
      });

      return {
        ...state,
        bot: {
          ...state.bot,
          files: orderBy(newFiles, 'lastEdited', 'desc'),
          synced,
        },
      };
    }

    case UPDATE_WEBSITE: {
      const answerData = extractAnswerData(action.payload, true);
      const newAnswers = state.bot.websites?.map(answer => {
        if (answerData.jid === answer.jid) {
          return answerData;
        }
        return answer;
      });

      return {
        ...state,
        bot: {
          ...state.bot,
          websites: orderBy(newAnswers, 'lastEdited', 'desc'),
          synced,
        },
      };
    }

    case DELETE_ANSWERBANK: {
      const answerBank = state.bot[action.payload.type + 's'];
      const deletedAnswer = answerBank?.find(
        item => item.jid === action.payload.answerId
      );

      const counts = {
        answerCount:
          deletedAnswer?.type === 'answer'
            ? state.bot.answerCount - 1
            : state.bot.answerCount,
        fileCount:
          deletedAnswer?.type === 'file'
            ? state.bot.fileCount - 1
            : state.bot.fileCount,
        websiteCount:
          deletedAnswer?.type === 'website'
            ? state.bot.websiteCount - 1
            : state.bot.websiteCount,
      };

      const filteredAnswers = answerBank?.filter(
        item => item.jid !== action.payload.answerId
      );
      const updatedBots = state.bots?.map(bot => {
        if (bot.jid === state.bot.jid) {
          return {
            ...bot,
            answerCount: counts.answerCount,
            fileCount: counts.fileCount,
            websiteCount: counts.websiteCount,
          };
        }
        return bot;
      });

      return {
        ...state,
        bots: updatedBots,
        bot: {
          ...state.bot,
          ...counts,
          [action.payload.type + 's']: [...filteredAnswers],
          synced,
        },
      };
    }

    case SET_DEFAULT_ANSWER: {
      const defaultAnswerPayload = action.payload;
      const defaultAnswerData = defaultAnswerPayload.map(answerItem => {
        return extractDefaultAnswer(answerItem.context, answerItem.jid);
      });
      return {
        ...state,
        bot: {
          ...state.bot,
          defaultAnswer: [...state.bot?.defaultAnswer, ...defaultAnswerData],
        },
      };
    }

    case REMOVE_ANSWER_QLINK: {
      let answersArr = [];
      for (const item of state.bot.answers) {
        if (item.jid === action.payload.answer) {
          if (item.qlinks && item.qlinks.length) {
            item['qlinks'] = item.qlinks.filter(
              answer => answer !== action.payload.question
            );
          }
          answersArr.push(item);
        } else {
          answersArr.push(item);
        }
      }
      return {
        ...state,
        bot: {
          ...state.bot,
          answers: [...answersArr],
        },
      };
    }

    case ADD_ANSWER_QLINK:
      let updatedAnswerQlink = [];
      for (const item of state.bot.answers) {
        if (item.jid === action.payload.answer) {
          item.qlinks.push(action.payload.question);
        }
        updatedAnswerQlink.push(item);
      }
      return {
        ...state,
        bot: {
          ...state.bot,
          answers: [...updatedAnswerQlink],
        },
      };

    case UPDATE_ANSWER_HITCOUNT: {
      const answer = getNodesFromArray(action.payload);
      let answersArr = [];
      if (answer.type === 'answer') {
        for (const item of state.bot.answers) {
          if (item.jid === action.payload.jid) {
            answersArr.push({
              ...item,
              hitcount: action.payload.hitcount,
            });
          } else {
            answersArr.push(item);
          }
        }
        return {
          ...state,
          bot: {
            ...state.bot,
            answers: [...answersArr],
          },
        };
      } else {
        for (const item of state.bot.defaultAnswer) {
          if (item.jid === action.payload.jid) {
            answersArr.push({
              ...item,
              hitcount: action.payload.hitcount,
            });
          } else {
            answersArr.push(item);
          }
        }
        return {
          ...state,
          bot: {
            ...state.bot,
            defaultAnswer: [...answersArr],
          },
        };
      }
    }

    case SET_SHOW_ANSWER_VERSIONS:
      return {
        ...state,
        bot: {
          ...state.bot,
          showAnswersVersions: action.payload || [],
        },
      };

    case SET_SHOW_DRAFT_RESPONSES:
      return {
        ...state,
        bot: {
          ...state.bot,
          showDraftResponses: !state.bot.showDraftResponses,
        },
      };

    case SET_SHOW_BELOW_THRESHOLD_RESPONSES:
      return {
        ...state,
        bot: {
          ...state.bot,
          showBelowThresholdResponses: !state.bot.showBelowThresholdResponses,
        },
      };

    case BULK_DELETE_ANSWERS:
      const bulkDeleteResults = state.bot.answers.filter(
        item => !action.payload.includes(item.jid)
      );
      return {
        ...state,
        bot: { ...state.bot, answers: [...bulkDeleteResults], synced },
      };

    case RESET_WS_GENERATED_ANSWER:
      return {
        ...state,
        bot: {
          ...state.bot,
          generated_answer: '',
        },
      };

    case SET_WS_ASK_QUESTION_ACTION: {
      const actionList =
        state?.webSocket?.askQuestionActions.length > 0
          ? [...(state?.webSocket?.askQuestionActions || []), action.payload]
          : [action.payload];
      const { chatList } = state.bot;
      const newGeneratedAnswer = action?.payload?.data?.content || '';
      const generatedAnswer = state.webSocket.generatedAnswer || '';
      const concatenatedAnswer = generatedAnswer + newGeneratedAnswer;
      const isTriggerIdMatch = chatList
        ? chatList[chatList?.length - 1]?.questionPayload?.metadata
            ?.interactionId === action?.payload?.trigger_id
        : false;

      if (action.payload.status === 'processing' && isTriggerIdMatch) {
        const isBotOpenAIEnabled = state.bot?.useOpenAI;
        const chatAnswer = extractGeneratedAnswer(
          concatenatedAnswer,
          isBotOpenAIEnabled
        );

        const updatedChatList = chatList?.map((chatItem, idx) => {
          if (idx === chatList?.length - 1) {
            return {
              ...chatItem,
              answer: chatAnswer,
              answerContext: {
                ...action.payload?.context,
                error: action.payload?.error || undefined,
                name: action.payload?.name,
              },
            };
          }
          return chatItem;
        });

        return {
          ...state,
          bot: {
            ...state.bot,
            chatList: updatedChatList,
          },
          webSocket: {
            ...state.webSocket,
            askQuestionActiveAction: action.payload,
            askQuestionActions: actionList,
            generatedAnswer: concatenatedAnswer,
            sending: true,
          },
        };
      } else if (action.payload.type === 'response' && isTriggerIdMatch) {
        const isBotOpenAIEnabled = state.bot?.useOpenAI;
        const chatAnswer = extractAnswerData(
          action.payload.answer,
          isBotOpenAIEnabled
        );

        const updatedChatList = chatList?.map((chatItem, idx) => {
          if (idx === chatList?.length - 1 && chatItem.answer) {
            return {
              ...chatItem,
              answer: chatAnswer,
              answerContext: {
                ...action.payload?.context,
                error: action.payload?.answer.error || undefined,
                name: action.payload?.answer.name,
              },
            };
          } else if (!chatItem.answer) {
            return {
              ...chatItem,
              answer: chatAnswer,
              answerContext: {
                ...action.payload?.context,
                error: action.payload?.error || undefined,
                name: action.payload?.name,
              },
            };
          }
          return chatItem;
        });

        return {
          ...state,
          bot: {
            ...state.bot,
            chatList: updatedChatList,
          },
          webSocket: {
            ...state.webSocket,
            askQuestionActiveAction: [],
            askQuestionActions: [],
            generatedAnswer: null,
            sending: false,
          },
        };
      }

      return {
        ...state,
        webSocket: {
          ...state.webSocket,
          askQuestionActiveAction: action.payload,
          askQuestionActions: actionList,
          generatedAnswer,
          sending: true,
        },
      };
    }

    case SET_WS_SCRAPER_ACTION: {
      const activeAction = state?.webSocket?.activeAction;

      const payload = action.payload;
      if (activeAction !== 'stop_scrapping') {
        if (payload?.pending?.length === 0 && !payload?.processing) {
          return {
            ...state,
            webSocket: {
              ...state.webSocket,
              scan:
                activeAction === 'add_file'
                  ? []
                  : extractScannedObject(payload.scanned),
              sending: false,
            },
          };
        } else {
          const extractedUrls = categorizedWebsitePages(
            extractScannedObject(payload.scanned)
          );

          const pending = payload?.pending?.map(url => {
            return {
              status: 'pending',
              url,
            };
          });

          // UNCOMMENT: IF you want to use the scaped again
          // const scapped = payload?.scapped?.map(scapped => {
          //   return {
          //     status: scanned.error ? 'error' : 'scapped',
          //     url: scapped.url,
          //     error: scanned.error,
          //   };
          // });

          const scanned = extractScannedObject(payload.scanned)?.map(
            scannedUrl => {
              return {
                status: 'scanned',
                url: scannedUrl,
              };
            }
          );

          // const scannedOnly = scraped
          //   ?.filter(
          //     scrapedURL =>
          //       !scanned.some(scannedUrl => {
          //         return scrapedURL.url === scannedUrl.url;
          //       })
          //   )
          //   ?.map(res => {
          //     return {
          //       status: 'scannedOnly',
          //       url: res.url,
          //     };
          //   });

          return {
            ...state,
            webSocket: {
              ...state.webSocket,
              scan: [
                ...scanned,
                // ...scannedOnly,
                { status: 'processing', url: payload?.processing?.url },
                ...pending,
              ],
              categorizedUrls: [
                { name: 'processing', urlList: [] },
                ...orderBy(extractedUrls, 'name', 'asc'),
              ],
              sending: true,
            },
          };
        }
      }
      return state;
    }

    case RESET_WS_SCANNER: {
      return {
        ...state,
        webSocket: {
          ...state.webSocket,
          scan: [],
          categorizedUrls: [],
          sending: false,
          scannedTableDataSource: null,
          categorizedTableDataSource: null,
          activeAction: null,
          triggerID: null,
        },
      };
    }

    case SET_WS_ASK_QUESTION_SENDING:
      return {
        ...state,
        webSocket: {
          ...state.webSocket,
          sending: true,
        },
      };

    case WS_ADD_FILE: {
      const newAnswers = extractFileData(action.payload);
      const isWebsite = newAnswers[0]?.scrapingInfo;
      const updatedBots = state.bots.map(bot => {
        if (bot.jid === state.bot.jid) {
          return {
            ...bot,
            websiteCount: isWebsite
              ? newAnswers.length + bot.websiteCount
              : bot.websiteCount,
            fileCount: !isWebsite
              ? newAnswers.length + bot.fileCount
              : bot.fileCount,
          };
        }
        return bot;
      });

      return {
        ...state,
        bots: updatedBots,
        bot: {
          ...state.bot,
          websites: isWebsite
            ? [...newAnswers, ...state.bot.websites]
            : state.bot.websites,
          files: !isWebsite
            ? [...newAnswers, ...state.bot.files]
            : state.bot.files,
          synced,
          websiteCount: isWebsite
            ? newAnswers.length + state.bot.websiteCount
            : state.bot.websiteCount,
          fileCount: !isWebsite
            ? newAnswers.length + state.bot.fileCount
            : state.bot.fileCount,
        },
        webSocket: {
          ...state.webSocket,
          scan: [],
          categorizedUrls: [],
          sending: false,
          fileAdded: newAnswers,
          websiteFile: action.payload,
        },
      };
    }

    case RESET_WS_ADD_FILE_ADDED:
      return {
        ...state,
        webSocket: {
          ...state.webSocket,
          fileAdded: [],
          scannedTableDataSource: [],
          categorizedTableDataSource: [],
          scan: [],
          categorizedUrls: [],
          activeAction: null,
          triggerID: null,
          websiteFile: [],
        },
      };

    case WS_UPDATE_ACTION: {
      return {
        ...state,
        webSocket: {
          ...state.webSocket,
          scannedTableDataSource:
            action.payload.action === 'add_file'
              ? []
              : state?.webSocket?.scannedTableDataSource,
          activeAction: action.payload.action,
          triggerID: action.payload.triggerID,
          scan: [],
          categorizedUrls: [],
        },
      };
    }

    case UPDATE_WS_SCANNED_DATA_SOURCE: {
      return {
        ...state,
        webSocket: {
          ...state.webSocket,
          scannedTableDataSource: action?.payload.scannedList,
          categorizedTableDataSource: categorizedWebsitePages(
            action?.payload.scannedList
          ),
        },
        bot: {
          ...state.bot,
          websiteSelectedPages: {
            ...state.bot.websiteSelectedPages,
            ...action.payload.websiteSelectedPages,
          },
        },
      };
    }

    case WS_STOP_SCRAPER: {
      return {
        ...state,
        webSocket: {
          ...state.webSocket,
          scan: state.webSocket?.scan
            ?.filter(scanned => scanned.status === 'scanned')
            ?.map(scan => scan.url),
          sending: false,
          activeAction: 'stop_scrapping',
        },
      };
    }

    case SET_WEBSITE_SELECTED_PAGES: {
      return {
        ...state,
        bot: {
          ...state.bot,
          websiteSelectedPages: {
            ...state.bot.websiteSelectedPages,
            ...action.payload,
          },
        },
      };
    }

    case RESET_WEBSITE_SELECTED_PAGES: {
      return {
        ...state,
        bot: {
          ...state.bot,
          websiteSelectedPages: initialWebsiteSelectedPage,
        },
      };
    }

    default:
      return state;
  }
};
