import { Modal, message } from 'antd';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import axios from 'axios';
import { Context } from 'store/store';
import { apiService } from 'services/api.service';
import { ACTIONS, EVENTS, STATUS } from 'react-joyride';
import {
  RESET_WEBSITE_SELECTED_PAGES,
  SET_ONBOARDING_FLAG,
  SET_WEBSITE_SELECTED_PAGES,
  SET_WS_IMPORTER_ACTION,
  SET_WS_IMPORTER_PROCESS_COMPLETED,
  WS_STOP_SCRAPER,
} from 'store/action';
import {
  ADD_FILE,
  RESET_WS_ADD_FILE_ADDED,
  RESET_WS_SCANNER,
  UPDATE_WS_SCANNED_DATA_SOURCE,
  WS_UPDATE_ACTION,
} from 'store/action';
import {
  getTotalAnswersLeftSelector,
  websiteSelectedPageselector,
} from 'selectors/bot/answers';
import useSelector from 'store/useSelector';
import {
  SCAN_OPTIONS,
  INITIAL_SELECTED_PAGE_LIST,
  VALID_DOMAIN_LIST,
  DEFAULT_WEBSITE_FORM_STATE_VALUE,
} from 'constants/answerbank/website';
import {
  categorizedWebsitePages,
  extractScannedObject,
  extractWebsiteScanList,
  renderPreconfigData,
} from 'store/reducers/helpers/bot/answers';
import { uuid } from 'uuidv4';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { withPrefixUUID } from 'utils';

const useImportWebsiteModal = ({
  onClose,
  setShowUploadModal,
  setParsedFileAnswers,
  setIsImportBtnDisabled,
  fileType,
}) => {
  const controller = new AbortController();
  const [state, dispatch] = useContext(Context);
  const location = useLocation();
  const {
    bot: { jid, websites },
    sentinel,
    token,
    graph,
    plan,
    webSocket,
  } = state;
  const { plan_type, onboarding_flag } = plan;
  const { pathname } = location;
  const selectionState = useSelector(websiteSelectedPageselector);
  const answersAllowed = useSelector(getTotalAnswersLeftSelector);
  const [modalBtnLoading, setModalBtnLoading] = useState(false);
  const limitErrorMsg = `You can add only ${answersAllowed} Answers in ${plan_type.toUpperCase()} Plan. Upgrade your plan to add answers.`;
  const [version, setVersion] = useState(true);
  const [timeoutInSecond, setTimeoutInSecond] = useState(60);
  const [currentStep, setCurrentStep] = useState(0);
  const [crawlerParams, setCrawlerParams] = useState('start');
  const [pages, setPages] = useState(null);
  const [runTour, setRunTour] = useState(false);
  const [showImportTip, setShowImportTip] = useState(false);
  const [stepIndex, setStepIndex] = useState(0);
  const [parsedAnswers, setPasedAnswers] = useState([]);
  const [importErrorMsg, setImportErrorMsg] = useState(null);
  const [fileList, setFileList] = useState([]);
  const [importWarnMsg, setImportWarnMsg] = useState(null);
  const [websiteFormState, setWebsiteFormState] = useState(
    DEFAULT_WEBSITE_FORM_STATE_VALUE
  );

  const WITH_PREFIX_BOT_JID = withPrefixUUID(jid || pathname);

  const selectedTemplateDetails = useMemo(
    () =>
      SCAN_OPTIONS?.find(
        option => option.value === websiteFormState?.selectedTemplate
      ),
    [websiteFormState?.selectedTemplate]
  );

  window.onmessage = function (e) {
    const targetElement = e.data;
    if (targetElement.type == 'iframe-scraper-target') {
      setWebsiteFormState(prevState => {
        return {
          ...prevState,
          targetElement: {
            ...prevState.targetElement,
            draft: targetElement.target,
          },
        };
      });
    }
  };

  const handelWebsiteFormStateChanges = (newValue, key) => {
    setWebsiteFormState(prevState => {
      return { ...prevState, [key]: newValue };
    });
  };

  const allUrlIsValid = useMemo(() => {
    try {
      return websiteFormState?.urlList?.map(list => {
        const extractedUrl = VALID_DOMAIN_LIST?.find(domain =>
          list.url.includes(domain)
        )
          ? list.url
          : VALID_DOMAIN_LIST[0] + list.url;
        new URL(extractedUrl);
        return true;
      });
    } catch (error) {
      return false;
    }
  }, [websiteFormState?.urlList]);

  const verifyQueryParams = url => {
    const extractedURL = new URL(url);
    const filteredUrl = webSocket?.scannedTableDataSource?.filter(
      urlOptions => urlOptions.url === url
    )[0];

    switch (filteredUrl?.filterMethod) {
      case 'exactString':
        return url + '$';
      case 'origin':
        return extractedURL.origin;
      case 'originPath':
        return extractedURL.origin + extractedURL.pathname;
      default:
        return url;
    }
  };

  const parsedUrlFilter = () => {
    const extractedURL = new URL(websiteFormState?.urlList[0]?.url);

    switch (crawlerParams) {
      case 'exactString':
        return websiteFormState?.urlList[0].url + '$';
      case 'origin':
        return extractedURL.origin;
      case 'originPath':
        return extractedURL.origin + extractedURL.pathname;
      default:
        return websiteFormState?.urlList[0].url;
    }
  };

  const renderImportedUrlParams = () => {
    let parsedUrl =
      selectionState?.urlSelected?.length > 0
        ? selectionState?.urlSelected
        : websiteFormState?.urlList?.map(list => list.url);
    let crawler = null;

    // Uncomment: If you want to include the url inputted in the field when filtering
    // const crawlerParams = selectedTemplateDetails?.crawlerParams
    //   ? '^' + parsedUrlFilter()
    //   : null;

    const verifiedURLList = selectionState?.urlSelected?.map(url =>
      verifyQueryParams(url)
    );
    const scrappedUrl = verifiedURLList
      ?.map(url => '^' + url)
      ?.filter(urlList => urlList !== null);

    const getters = {
      method: websiteFormState?.targetElement ? 'selector' : 'default',
      expression: websiteFormState?.targetElement?.final,
    };

    if (websiteFormState?.selectedTemplate === 'pagination') {
      const generatedURLs = handleGeneratePaginatedUrl();
      parsedUrl = generatedURLs?.map((url, key) => {
        return {
          key,
          url,
        };
      });
    }

    if (selectedTemplateDetails?.crawlerParams === null) {
      setModalBtnLoading(true);
    }

    crawler =
      selectedTemplateDetails?.crawlerParams === null
        ? null
        : {
            filters: scrappedUrl.length > 0 ? scrappedUrl : null,
            depth: 1,
            selector: websiteFormState?.targetElement
              ? websiteFormState?.targetElement?.final + ' a[href]'
              : null,
          };

    if (!crawler?.selector) {
      delete crawler?.selector;
    }

    if (!getters.expression) {
      delete getters.expression;
    }

    return parsedUrl?.map(urlDetails => {
      const params = {
        goto: {
          url: urlDetails,
          wait_until: websiteFormState?.selectedUploadingMethod,
          timeout: timeoutInSecond * 1000,
        },
        getters: [getters],
        crawler,
      };

      if (!params.crawler || selectedTemplateDetails?.value !== 'traversing') {
        delete params.crawler;
      } else if (!params.crawler?.filters) {
        delete params.crawler.filters;
      } else if (!params.crawler?.webSocketChannel) {
        delete params.crawler?.webSocketChannel;
      }

      if (!params.method) {
        delete params.method;
        delete params.targetElement;
      }

      return params;
    });
  };

  const addFileSuccessMessage = () => {
    const urlCount =
      websiteFormState?.selectedTemplate === 'single'
        ? websiteFormState.urlList.length
        : selectionState.urlSelected?.length;

    return Modal.success({
      title: 'Import Completed!',
      content: (
        <div>
          <p>
            All {urlCount} Page
            {urlCount > 1 ? 's' : ''} has been imported without any issues.
          </p>
        </div>
      ),
      onOk() {},
    });
  };

  const handleAddFile = async () => {
    setModalBtnLoading(true);
    let pages;

    try {
      dispatch({
        type: WS_UPDATE_ACTION,
        payload: {
          action: 'add_file',
        },
      });

      if (answersAllowed <= 0) {
        return message.error(limitErrorMsg);
      }

      if (selectedTemplateDetails?.crawlerParams === null) {
        setModalBtnLoading(false);
        setCurrentStep(currentStep + 1);
      }
      pages = renderImportedUrlParams();

      const payload = {
        ver: version ? 'final' : 'draft',
        fileType,
        pages,
        uploadingMethod: websiteFormState?.selectedUploadingMethod,
        webSocketChannel: webSocket?.channel ? webSocket?.channel : null,
        preConfigs: renderPreconfigData(
          websiteFormState?.selectedUploadingMethod,
          timeoutInSecond,
          []
        ),
      };

      if (!payload.pages) {
        delete payload.pages;
      }

      if (websiteFormState?.selectedTemplate === 'single') {
        delete payload.preConfigs;
      }

      const response = await apiService.addFile(payload, token, jid, sentinel);

      if (response?.data?.is_queued !== true) {
        const filesResponse = response?.data?.report[0]?.success;
        setTimeout(() => {
          addFileSuccessMessage();
          resetStates();
          setCurrentStep(0);
          setTimeoutInSecond(60);
          dispatch({
            type: ADD_FILE,
            payload: {
              response: filesResponse,
              type: 'website',
            },
          });
          handleCloseUploadModal(true, true);
        }, 3000);
      }
    } catch (error) {
      if (axios.isCancel(error)) {
        setIsImportBtnDisabled(false);
        return message.error(error.message || 'Operation aborted by the user.');
      } else {
        setModalBtnLoading(false);
        setIsImportBtnDisabled(false);
        return message.error(
          error.message || `Something went wrong. Importing website failed.`
        );
      }
    }
  };

  const renderCrawlerParams = () => {
    const parsedUrl = parsedUrlFilter();
    const extractedURL = new URL(websiteFormState?.urlList[0]?.url);
    const params =
      websiteFormState?.selectedScanningMethod === 'all'
        ? {
            filters: ['^' + parsedUrl],
            depth: 1,
            selector: websiteFormState?.targetElement
              ? websiteFormState?.targetElement.final + ' a[href]'
              : null,
          }
        : {
            filters: ['^' + extractedURL],
            depth: 1,
            selector: websiteFormState?.targetElement
              ? websiteFormState?.targetElement.final + ' a[href]'
              : null,
          };

    if (!params.selector) {
      delete params.selector;
    }
    return params;
  };

  const handleScrapedUrl = async () => {
    setModalBtnLoading(true);
    let urlDataSource = [];
    const triggerID = uuid();
    dispatch({
      type: WS_UPDATE_ACTION,
      payload: {
        action: 'scrape',
        triggerID,
      },
    });

    try {
      const scrapedParams = {
        url: websiteFormState?.urlList[0]?.url,
        uploadingMethod: websiteFormState?.selectedUploadingMethod,
        timeoutInSecond,
        crawler: renderCrawlerParams(),
        webSocketChannel: webSocket?.channel,
        method: websiteFormState?.targetElement ? 'selector' : null,
        expression: websiteFormState?.targetElement?.final,
        triggerID,
      };
      if (!scrapedParams.crawler) {
        delete scrapedParams.crawler;
      } else if (!scrapedParams.crawler?.filters) {
        delete scrapedParams.crawler?.filters;
      } else if (!scrapedParams.crawler?.webSocketChannel) {
        delete scrapedParams.crawler?.webSocketChannel;
      }

      if (!scrapedParams.method) {
        delete scrapedParams.method;
        delete scrapedParams.targetElement;
      }

      const response = await apiService.scrappedUrl(
        scrapedParams,
        token,
        jid,
        sentinel
      );

      const scannedResponse = response.data?.report[0]?.scanned
        ? extractScannedObject(response.data?.report[0]?.scanned)
        : [];

      if (scannedResponse?.length > 1) {
        dispatch({
          type: UPDATE_WS_SCANNED_DATA_SOURCE,
          payload: [
            ...extractWebsiteScanList(scannedResponse),
            {
              key: urlDataSource.length,
              url: websiteFormState?.urlList[0]?.url,
            },
          ],
        });
      }

      setCurrentStep(currentStep + 1);
      setModalBtnLoading(false);
    } catch (error) {
      if (axios.isCancel(error)) {
        setIsImportBtnDisabled(false);
        return message.error(error.message || 'Operation aborted by the user.');
      } else {
        setModalBtnLoading(false);
        setIsImportBtnDisabled(false);
        return message.error(
          error.message || `Something went wrong. File upload failed.`
        );
      }
    }
  };

  const resetStates = () => {
    setModalBtnLoading(false);
    setPages(null);
    setWebsiteFormState(DEFAULT_WEBSITE_FORM_STATE_VALUE);
    handleRemoveFile();
    dispatch({
      type: RESET_WEBSITE_SELECTED_PAGES,
    });
  };

  const resetModal = useCallback(() => {
    setParsedFileAnswers([]);
    setShowUploadModal(false);
  }, [setParsedFileAnswers, setShowUploadModal]);

  const handleCloseUploadModal = useCallback(() => {
    onClose();
  }, [modalBtnLoading, onClose, resetModal, setShowUploadModal]);

  const handleCancelImport = useCallback(() => {
    controller.abort();
    resetStates();
    dispatch({
      type: RESET_WS_SCANNER,
    });
    return handleCloseUploadModal(true);
  }, [controller, handleCloseUploadModal]);

  const handleBackward = () => {
    setCurrentStep(0);
    resetStates();
    dispatch({
      type: RESET_WS_SCANNER,
    });
  };

  const parsePageRange = pageRange => {
    const [start, end] = pageRange?.split('-').map(Number);
    return Array.from({ length: end - start + 1 }, (_, i) =>
      websiteFormState?.urlList[0]?.url.replace('{pages}', start + i)
    );
  };

  const handleGeneratePaginatedUrl = () => {
    if (websiteFormState?.urlList[0]?.url) {
      const pagesList = pages?.split(',').map(part => part.trim());
      let renderPageList = [];
      for (const page of pagesList) {
        if (page.includes('-')) {
          renderPageList = renderPageList.concat(parsePageRange(page));
        } else {
          renderPageList.push(
            websiteFormState?.urlList[0]?.url?.replace('{pages}', page)
          );
        }
      }

      // setPaginatedURLList(renderPageList);
      return renderPageList;
    }
  };

  const handleStopScrapping = async () => {
    try {
      const response = await apiService.stopScrapping(
        webSocket?.triggerID,
        token,
        jid,
        sentinel
      );

      if (response?.data?.success) {
        dispatch({
          type: WS_STOP_SCRAPER,
        });
        message.error(`Fetching stopped.`);
      }
    } catch (error) {
      setModalBtnLoading(false);
      return message.error(
        error.message || `Something went wrong. Stop fetching failed.`
      );
    }
  };

  const handleCloseElementSelecting = () => {
    handelWebsiteFormStateChanges(false, 'isTargetElementOpen');
  };

  const handleSaveElement = () => {
    setWebsiteFormState(prevState => {
      return {
        ...prevState,
        isTargetElementOpen: false,
        targetElement: {
          ...prevState.targetElement,
          final: prevState?.targetElement?.draft,
        },
      };
    });
  };

  const handleBackToPagesList = () => {
    handelWebsiteFormStateChanges(
      INITIAL_SELECTED_PAGE_LIST,
      'selectedPageList'
    );
  };

  const getSelectedRowKeysFromUrls = (urls, data) => {
    return data?.filter(item => urls.includes(item.url)).map(item => item.key);
  };

  const getSelectedRowKeysFromCategories = (categories, data) => {
    return data
      ?.filter(item => categories.includes(item.name))
      .map(item => item.key);
  };

  const onClickCallback = async data => {
    const { action, index, type, status } = data;
    if (STATUS.FINISHED === status || STATUS.SKIPPED === status) {
      setRunTour(false);
      setStepIndex(0);
      if (!onboarding_flag.includes('TextIngest')) {
        await apiService.setOnboardingFlag(
          sentinel,
          token,
          graph,
          'TextIngest'
        );
        dispatch({ type: SET_ONBOARDING_FLAG, payload: 'TextIngest' });
      }
    } else if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      const stepIndex = index + (action === ACTIONS.PREV ? -1 : 1);
      setStepIndex(stepIndex);
    }
  };

  useEffect(() => {
    const urlSelectedRowKeys = getSelectedRowKeysFromUrls(
      selectionState.urlSelected,
      webSocket?.scannedTableDataSource
    );
    const categorySelectedRowKeys = getSelectedRowKeysFromCategories(
      selectionState.categorySelected,
      webSocket?.categorizedTableDataSource
    );

    dispatch({
      type: SET_WEBSITE_SELECTED_PAGES,
      payload: {
        urlSelectedRowKeys,
        categorySelectedRowKeys,
      },
    });
  }, []);

  useEffect(() => {
    if (!webSocket?.sending && webSocket?.scan?.length > 0) {
      const scannedList = extractWebsiteScanList(webSocket.scan);
      const extractedUrls = categorizedWebsitePages(scannedList);
      const websiteSelectedPages = {
        urlSelectedRowKeys: scannedList?.map(row => {
          return row.key;
        }),
        urlSelected: scannedList?.map(row => {
          return row.url;
        }),
        categorySelected: extractedUrls?.map(row => {
          return row.name;
        }),
        categorySelectedRowKeys: extractedUrls?.map(row => {
          return row.key;
        }),
      };

      dispatch({
        type: UPDATE_WS_SCANNED_DATA_SOURCE,
        payload: { scannedList, websiteSelectedPages },
      });
    }
  }, [webSocket?.sending, webSocket?.scan]);

  const handleUploadFile = info => {
    setFileList([info]);
    const reader = new FileReader();
    reader.onload = e => {
      const answers = JSON.parse(e.target.result);
      const websiteList = websites?.map(site => site.text);
      const uploadedFileData = answers?.bot[0]?.website;
      const duplicateWebsite = uploadedFileData?.filter(website =>
        websiteList?.includes(website.name)
      );
      if (duplicateWebsite.length > 0) {
        setImportErrorMsg('Duplicate website found. Please review the file.');
      } else if (answersAllowed < uploadedFileData.length) {
        setImportWarnMsg(
          `Maximum number of answers reached. System will only upload ${answersAllowed} answer(s).`
        );
      } else {
        setImportErrorMsg(null);
        setImportWarnMsg(null);
      }

      if (answers?.bot[0]?.website) {
        setPasedAnswers(answers?.bot[0]?.website);
      } else {
        setImportErrorMsg(`No valid website found from the file.`);
      }
    };
    reader.readAsText(info);
    return false;
  };

  const handleRemoveFile = () => {
    setParsedFileAnswers([]);
    setImportErrorMsg(null);
    setImportWarnMsg(null);
    setParsedFileAnswers([]);
    setFileList([]);
    // setIsImportBtnDisabled(true);
  };

  const handleAddWebsiteJson = async () => {
    try {
      const triggerID = uuid();
      setModalBtnLoading(true);
      const res = await apiService.importer(
        sentinel,
        jid,
        token,
        { website: parsedAnswers },
        webSocket.channel,
        triggerID
      );

      if (res?.data?.is_queued) {
        dispatch({
          type: SET_WS_IMPORTER_ACTION,
          payload: triggerID,
        });
      }
    } catch (error) {
      if (axios.isCancel(error)) {
        setIsImportBtnDisabled(false);
        return message.error(error.message || 'Operation aborted by the user.');
      } else {
        setModalBtnLoading(false);
        setIsImportBtnDisabled(false);
        return message.error(
          error.message || `Something went wrong. Importing website failed.`
        );
      }
    }
  };

  useEffect(() => {
    if (webSocket?.fileAdded?.length > 0) {
      addFileSuccessMessage();
      setCurrentStep(0);
      setTimeoutInSecond(60);
      resetStates();
      dispatch({
        type: RESET_WS_ADD_FILE_ADDED,
      });
      handleCloseUploadModal(true, true);
    }
  }, [webSocket?.fileAdded]);

  useEffect(() => {
    setTimeout(() => {
      setRunTour(true);
    }, 3000);
  }, [onboarding_flag]);

  useEffect(() => {
    const fetchWebsiteList = async () => {
      const websiteResponse = await apiService.getFiles(
        sentinel,
        WITH_PREFIX_BOT_JID,
        token
      );

      dispatch({
        type: SET_WS_IMPORTER_PROCESS_COMPLETED,
        payload: websiteResponse?.data?.report[0]?.filter(
          website =>
            website.context.scraping_info &&
            website.context.scraping_info !== null
        ),
      });
    };

    if (webSocket.activeAction === 'completed') {
      fetchWebsiteList();
      handleCloseUploadModal(true, true);
      setModalBtnLoading(false);
      setIsImportBtnDisabled(false);
      message.success('Website Successfully Imported!');
    }
  }, [webSocket]);

  return {
    modalBtnLoading,
    handleCancelImport,
    handleAddFile,
    setVersion,
    version,
    currentStep,
    handleScrapedUrl,
    selectionState,
    handleBackward,
    webSocket,
    allUrlIsValid,
    handleCloseElementSelecting,
    handleSaveElement,
    selectedTemplateDetails,
    handleBackToPagesList,
    onClickCallback,
    runTour,
    stepIndex,
    handleStopScrapping,
    setModalBtnLoading,
    crawlerParams,
    setCrawlerParams,
    setWebsiteFormState,
    websiteFormState,
    handelWebsiteFormStateChanges,
    showImportTip,
    setShowImportTip,
    handleUploadFile,
    handleRemoveFile,
    importErrorMsg,
    importWarnMsg,
    handleAddWebsiteJson,
    parsedAnswers,
    fileList,
  };
};

export default useImportWebsiteModal;
