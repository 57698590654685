import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect, useLocation } from 'react-router-dom';
import useWebSocket, { ReadyState } from 'react-use-websocket';

import { BOT_REDIRECT } from 'constants/localStorage';
import ROUTES from 'constants/routes';
import { Context } from 'store/store';
import { getTokenSelector } from 'selectors/user';
import useSelector from 'store/useSelector';
import {
  DISCONNECT_WEBSOCKET,
  SET_WS_ASK_QUESTION_ACTION,
  SET_WS_IMPORTER_PROGRESS_COUNT,
  SET_WS_SCRAPER_ACTION,
  SET_WS_TOKEN,
  UPDATE_BOT_SYNC,
  WS_ADD_FILE,
} from 'store/action';
import { RECONNECT_INTERVAL_IN_MS, SOCKET_URL } from 'constants/websocket';
import { isInitialWebsocketStateSelector } from 'selectors/websocket';

const PrivateRoute = props => {
  const { component: Component, ...rest } = props;
  const [state, dispatch] = useContext(Context);
  const {
    isAuthenticated,
    bot: { strippedBotID, jid },
    webSocket,
  } = state;
  const token = useSelector(getTokenSelector);
  const isInitialWebsocketState = useSelector(isInitialWebsocketStateSelector);
  const { pathname } = useLocation();
  const { sendJsonMessage, lastMessage, readyState } = useWebSocket(
    SOCKET_URL,
    {
      shouldReconnect: closeEvent => true,
      retryOnError: () => true,
      reconnectInterval: RECONNECT_INTERVAL_IN_MS,
      onError: e => {
        if (!isInitialWebsocketState) {
          dispatch({
            type: DISCONNECT_WEBSOCKET,
          });
        }
      },
      onClose: e => {
        if (!isInitialWebsocketState) {
          dispatch({
            type: DISCONNECT_WEBSOCKET,
          });
        }
      },
    }
  );

  const nextLocation = localStorage.getItem(BOT_REDIRECT);
  const pathBotJID = pathname.split('/bot/').pop();

  // Rename readyState from number to human readable word
  const connectionStatus = {
    [ReadyState.CONNECTING]: 'Connecting',
    [ReadyState.OPEN]: 'Open',
    [ReadyState.CLOSING]: 'Closing',
    [ReadyState.CLOSED]: 'Closed',
    [ReadyState.UNINSTANTIATED]: 'Uninstantiated',
  }[readyState];

  useEffect(() => {
    const clientConnectPayload = {
      type: 'client_connect',
      data: {
        token,
      },
    };

    switch (connectionStatus) {
      case 'Open':
        if (!webSocket?.channel && token) {
          sendJsonMessage(clientConnectPayload);
        }
        break;

      default:
        break;
    }
  }, [connectionStatus, webSocket?.channel, token]);

  useEffect(() => {
    if (lastMessage?.data) {
      const wsData = JSON.parse(lastMessage.data);
      switch (wsData.type) {
        case 'client_connected':
          if (!webSocket?.channel) {
            dispatch({
              type: SET_WS_TOKEN,
              payload: wsData.data,
            });
          }
          break;

        case 'ask_question':
          dispatch({
            type: SET_WS_ASK_QUESTION_ACTION,
            payload: wsData.data,
          });
          break;

        case 'scraper':
          dispatch({
            type: SET_WS_SCRAPER_ACTION,
            payload: wsData.data,
          });
          break;

        case 'add_file':
          dispatch({
            type: WS_ADD_FILE,
            payload: wsData.data?.answer?.success,
          });
          break;

        case 'sync_bot':
          dispatch({
            type: UPDATE_BOT_SYNC,
            payload: {
              jid,
            },
          });
          break;

        case 'importer':
          dispatch({
            type: SET_WS_IMPORTER_PROGRESS_COUNT,
            payload: wsData.data,
          });
          break;

        default:
          break;
      }
    }
  }, [lastMessage, webSocket?.channel]);

  if (!nextLocation && !!pathBotJID && pathname.includes('/bot/') && !token) {
    const strippedURL = window.location.href.replace(
      // remove if uuid prefix is present
      /urn:uuid:.*&/,
      strippedBotID + '&'
    );
    localStorage.setItem(BOT_REDIRECT, strippedURL);
  }

  return (
    <Route
      {...rest}
      render={props =>
        isAuthenticated === true ? (
          <Component {...rest} />
        ) : (
          <Redirect
            to={{
              pathname: ROUTES.LOGIN,
              state: { from: nextLocation },
            }}
          />
        )
      }
    />
  );
};

PrivateRoute.propTypes = {
  component: PropTypes.any,
};

export default PrivateRoute;
